<template>
    <div class="earlynewsbusmore_bigbox">
        <div class="earlynewsbusmore_box">
            <img class="earlynewsbusmore_img" src="../../assets/home/news1.png" alt="">
            <div class="earlynewsbusmore_content_bigbox">
                <div class="earlynewsbusmore_content_box" v-for="(item,index) in yearsLists" :key="index">
                    <div style="height:0.3rem"></div>
                    <div @click="selectyear = true" class="earlynewsbusmore_content_years">
                        <div>{{item.years}}</div>
                        <SvgIcon class="earlynewsbusmore_content_svg" iconClass="icon-xiajiantou"></SvgIcon>
                    </div>
                    <div @click="golink(index,indexs)" class="earlynewsbusmore_content_smallbox"
                        v-for="(items,indexs) in item.yearsList" :key="indexs">
                        <div class="earlynewsbusmore_content_leftbox">
                            <div class="earlynewsbusmore_content_dian"></div>
                            <div class="earlynewsbusmore_content_month">{{items.month}}月</div>
                            <div class="earlynewsbusmore_content_xian"></div>
                        </div>
                        <div class="earlynewsbusmore_content_title"> {{items.title}}</div>
                    </div>

                    <div style="height: 0.15rem"></div>
                </div>
            </div>
        </div>
        <!-- 弹出框 -->
        <div class="earlynewsbusmore_selectyear_bigbox" @click="selectyear = false" v-if="selectyear">
            <div class="earlynewsbusmore_selectyear_box" @click.stop=''>
                <div @click="selectyear = false">
                    <SvgIcon class="earlynewsbusmore_selectyear_close" iconClass="icon-guanbi"></SvgIcon>
                </div>
                <div class="earlynewsbusmore_selectyear_title">筛选年份</div>
                <div class="earlynewsbusmore_selectyear_smallbox">
                    <div :class="[item.selectedtype == true?'selectedstatus':'']" @click="selectyears(index)"
                        v-for="(item,index) in selectyearList" :key="index">{{item.year}}年</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { postmorningNewsBuslistAPI,postCommonAddClick } from '../../api/home'
export default {
    name: 'homecontent',
    // 更多 新闻早班车
    components: {

    },
    data() {
        return {
            selectyearList: [
                // 弹出框
                // {
                //     year: 2022,
                //     selectedtype: false
                // },
            ],
            selectyear: false,
            yearsLists: [],
            allyearsList: [

            ]

        }
    },
    created() {
        this.postmorningNewsBuslist()
    },
    methods: {
        selectyears(e) {
            this.selectyearList.map(v => {
                v.selectedtype = false
            })
            this.selectyearList[e].selectedtype = true
            this.yearsLists = [] //重置显示的列表
            // 获取点击的是哪年 this.selectyearList[e].year
            this.allyearsList.map(v => {
                if (v.years == this.selectyearList[e].year) {
                    this.yearsLists.unshift(v) //往数组第一个添加
                } else {
                    this.yearsLists.push(v)
                }
            })
            this.selectyear = false

        },
        clickGoHome() {
            this.$router.push("/")
        },
        clickGoMy() {
            this.$router.push("/my")
        },
        postmorningNewsBuslist() {
            // 新闻早班车 更多 列表
            let data = {
                kv: {},
                page: 0,
                pageSize: 10
            }
            postmorningNewsBuslistAPI(data)
                .then(res => {
                    let objlist = res.data.data || {}
                    let newyearsLists = [] //组装列表
                    for (let item in objlist) {
                        // 组装弹出框
                        let selectyearListobj = {}
                        selectyearListobj['year'] = item
                        selectyearListobj['selectedtype'] = false
                        this.selectyearList.push(selectyearListobj) //弹出框
                        this.selectyearList.sort((a, b) => {
                            return b.year - a.year // 降序
                        })
                        //组装列表
                        let allyearsarr = objlist[item] || []
                        let allyears = [] //里层
                        allyearsarr.map(v => {
                            let newobj = {}
                            newobj['title'] = v.title
                            newobj['morningNewsBusID'] = v.morningNewsBusID
                            newobj['month'] = v.month
                            newobj['type'] = v.type
                            newobj['link'] = v.link
                            if (v.source) newobj['source'] = v.source
                            if (v.serviceID) newobj['serviceID'] = v.serviceID
                            if (v.video) {
                                let videourl = JSON.parse(v.video)
                                newobj['videos'] = videourl.url
                            }
                            allyears.push(newobj)
                        })
                        let newobjs = {} //外层
                        newobjs['years'] = item
                        newobjs['yearsList'] = allyears
                        newyearsLists.push(newobjs)
                    }
                    this.yearsLists = newyearsLists
                    this.yearsLists.sort((a, b) => {
                            return b.years - a.years // 降序
                        })
                    this.allyearsList = newyearsLists

                })
        },
        golink(index, indexs) {
            // 去外链 index 外层下标  indexs 里层下标
            // let newlink = this.yearsLists[index].yearsList[indexs].link
            // if (newlink) window.location.href = newlink

            //计算浏览量
            postCommonAddClick('morningNewsBus', this.yearsLists[index].yearsList[indexs].morningNewsBusID)
                .then(res => {
                })
            if (this.yearsLists[index].yearsList[indexs].source == 2) {
                this.$router.push({
                    name: 'article',
                    query: {
                        serviceID: this.yearsLists[index].yearsList[indexs].serviceID,
                        from: "新闻早班车"
                    }
                })
                return
            }
                if (this.yearsLists[index].yearsList[indexs].link) window.location.href = this.yearsLists[index].yearsList[indexs].link
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.earlynewsbusmore_bigbox {
    width: 100%;
    height: 100%;

}

.earlynewsbusmore_box {
    width: 100%;
    height: 100vh;
    position: relative;
    background: #F8F8F8;
    overflow-y: scroll;
}

.earlynewsbusmore_img {
    width: @750px;
    height: @320px;
    object-fit: cover;
}

.earlynewsbusmore_content_bigbox {
    width: @690px;
    position: absolute;
    left: @30px;
    top: @260px;
}

.earlynewsbusmore_content_box {
    width: 100%;
    background: #FFF;
    border-radius: @20px;
    padding-bottom: @40px;
    margin-bottom: @40px;

}

.earlynewsbusmore_content_years {
    width: @140px;
    height: @48px;
    margin-left: @30px;
    font-size: @34px;
    font-weight: 530;
    line-height: @48px;
    position: relative;

}

.earlynewsbusmore_content_svg {
    width: @20px;
    height: @20px;
    position: absolute;
    left: @80px;
    top: @16px;

}

.earlynewsbusmore_content_smallbox {
    width: 95%;
    font-size: @32px;
    margin-top: @30px;
    display: flex;
    color: #333333;
}

.earlynewsbusmore_content_leftbox {
    width: @200px;
    height: @45px;

    display: flex;
    align-items: center;
}

.earlynewsbusmore_content_month {
    margin-left: @15px;
    width: @70px;
}

.earlynewsbusmore_content_title {
    width: 100%;
    margin-left: @5px;
    line-height: @45px;

}

.earlynewsbusmore_content_dian {
    width: @10px;
    height: @10px;
    border-radius: @10px;
    background: #2878FF;
    margin-left: @30px;
}

.earlynewsbusmore_content_xian {
    width: @1px;
    height: @26px;
    background: #999999;
    margin-left: @10px;
    margin-right: @10px;
}

.earlynewsbusmore_selectyear_bigbox {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
}

.earlynewsbusmore_selectyear_box {
    width: @640px;
    height: @390px;
    border-radius: @20px;
    background: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.earlynewsbusmore_selectyear_close {
    width: @30px;
    height: @30px;
    position: absolute;
    top: @30px;
    right: @30px;
}

.earlynewsbusmore_selectyear_title {
    width: 100%;
    height: @50px;
    font-size: @36px;
    margin-top: @40px;
    text-align: center;
    font-weight: 520;
}

.earlynewsbusmore_selectyear_smallbox {
    margin-left: @10px;
    display: flex;
    flex-wrap: wrap;
}

.earlynewsbusmore_selectyear_smallbox>div {
    width: @280px;
    height: @60px;
    margin-top: @20px;
    margin-left: @20px;
    text-align: center;
    line-height: @60px;
    border: @1px solid #CCCCCC;
    font-size: @28px;
}

.selectedstatus {
    background: #00B277;
    color: #FFF;
}

.navber_bottom_box {
    width: 100%;
    height: 7vh;
    display: flex;
    position: fixed;
    bottom: 0;
}

.navber_bottom_smallBox {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @20px;
}

.navber_bottom_svg {
    width: @40px;
    height: @40px;
    margin-left: 50%;
    transform: translate(-50%);
}
</style>
