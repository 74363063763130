<template>
    <div class="BIO_bigBox">
        <div class="BIO_box">
            <div class="BIO_title">BIO生活</div>
            <div class="BIO_rollBox">
                <div class="BIO_smallBox" @click="goBIOmore(index)" v-for="(item,index) in BIOrollBoxList" :key="index"
                    :class="(index + 1) == BIOrollBoxList.length ?'BIO_smallBoxs':''">
                    <div class="BIO_smallBox_blackbox"></div>
                    <img class="BIO_smallBox_img" :src="item.url" alt="">
                    <div class="BIO_smallBox_title">{{item.title}}</div>
                </div>
            </div>
<!--            <div @click="golink(index)" class="BIO_contentbox" v-for="(item,index) in BIOcontentBoxList" :key="index">-->
<!--                <div class="BIO_contentbox_imgs">-->
<!--                    <div v-if="item.type == 1" class="my_swipe_videoplayerbox"></div>-->
<!--                    <div v-if="item.type == 1" class="">-->
<!--                        <SvgIcon class="my_swipe_videoSvg" iconClass="icon-play"></SvgIcon>-->
<!--                    </div>-->
<!--                    <img class="BIO_contentbox_img" :src="item.urls" alt="">-->
<!--                </div>-->
<!--                <div class="BIO_contentbox_title">-->
<!--                    {{item.title}}-->
<!--                </div>-->
<!--                <div class="BIO_contentbox_time">-->
<!--                    <SvgIcon class="BIO_contentbox_svg" iconClass="icon-liulanliang"></SvgIcon>-->
<!--                    <span class="BIO_contentbox_Total">{{item.browseTotal}}</span>-->
<!--                    <span>{{item.time}}</span>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import { postbioNbioLifeTypeAPI, postbioLifeAPI,postCommonAddClick } from '../../api/home'
export default {
    name: 'BIO',
    //BIO生活
    components: {

    },
    data() {
        return {
            BIOrollBoxList: [

            ],
            BIOcontentBoxList: [

            ]
        }
    },
    methods: {
        postbioNbioLifeType() {
            // BIO 生活管理 横向轮播
            postbioNbioLifeTypeAPI()
                .then(res => {
                    let list = res.data.data || []
                    let newBIOrollBoxList = []
                    list.map(v => {
                        let obj = {}
                        obj['title'] = v.name
                        obj['bioLifeTypeID'] = v.bioLifeTypeID
                        let newpicture = JSON.parse(v.picture)
                        obj['url'] = newpicture[0].url
                        newBIOrollBoxList.push(obj)
                    })
                    this.BIOrollBoxList = newBIOrollBoxList
                })
        },
        postbioLife() {
            // BIO 生活管理
            postbioLifeAPI()
                .then(res => {
                    let list = res.data.data || []
                    list.map(v => {
                        let newpicture = JSON.parse(v.picture)
                        v.urls = newpicture[0].url
                        if (v.video) v.videos = JSON.parse(v.video) //videos.url
                        v.time = v.createTime.slice(0, 16)
                    })
                    this.BIOcontentBoxList = list
                })
        },
        goBIOmore(e) {
            this.$router.push({
                name: 'BIOmore',
                query: {
                    typeID: this.BIOrollBoxList[e].bioLifeTypeID
                }
            })
        },
        golink(index) {
            //计算浏览量
            postCommonAddClick('bioLife', this.BIOcontentBoxList[index].bioLifeID)
                .then(res => {
                })
            // 去外链
            if (this.BIOcontentBoxList[index].source == 2) {
                this.$router.push({
                    name: 'article',
                    query: {
                        serviceID: this.BIOcontentBoxList[index].serviceID,
                        from: "BIO生活"
                    }
                })
                return
            }
            if (this.BIOcontentBoxList[index].video) {
                this.$emit("videoUrl", this.BIOcontentBoxList[index].videos.url)
            } else {
                if (this.BIOcontentBoxList[index].link) window.location.href = this.BIOcontentBoxList[index].link
            }
        }
    },
    created() {
        this.postbioNbioLifeType()
        this.postbioLife()
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.BIO_bigBox {
    width: 100%;
}

.BIO_box {
    width: @700px;
    margin-left: @25px;
}

.BIO_title {
    font-size: @34px;
    width: @136px;
    height: @48px;
    font-weight: bold;
    margin-top: @30px;
}

.BIO_rollBox {
    width: 100%;
    height: @170px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: @20px;
    margin-bottom: @20px;
}

.BIO_smallBox {
    width: @240px;
    height: @170px;
    /* background: rgb(226, 105, 105); */
    display: inline-block;
    margin-right: @20px;
    position: relative;
    border-radius: @20px;
}

.BIO_smallBoxs {
    margin-right: 0;

}

::-webkit-scrollbar {
    display: none
}

.BIO_smallBox_img {
    width: 100%;
    height: 100%;
    border-radius: @20px;
    object-fit: cover;
}

.BIO_smallBox_blackbox {
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    border-radius: @20px;
}

.BIO_smallBox_title {
    width: 100%;
    color: #FFF;
    font-size: @30px;
    position: absolute;
    top: @29px;
    left: 0;
    text-align: center;
}

.BIO_contentbox {
    width: 100%;
}

.BIO_contentbox_imgs {
    width: @700px;
    height: @290px;
    border-radius: @20px;
    margin-top: @20px;
    position: relative;
}

.BIO_contentbox_img {
    width: @700px;
    height: @290px;
    border-radius: @20px;

    object-fit: cover;
}

.BIO_contentbox_title {
    font-size: @34px;
    margin-top: @20px;
}

.BIO_contentbox_time {
    margin-top: @20px;
    font-size: @26px;
    color: #999999;
    height: @36px;
    line-height: @36px;
}

.BIO_contentbox_svg {
    width: @26px;
    height: @22px;
}
.BIO_contentbox_Total{
    margin-left: @3px;
    margin-right: @20px;
}

.my_swipe_videoplayerbox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: @10px;
}

.my_swipe_videoSvg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: @60px;
    height: @60px;

}
</style>
