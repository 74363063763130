<template>
    <div class="content_bigbox">
        <div class="content_titletop"></div>
        <div class="content_title">
            {{content.title}}
        </div>
        <div class="content_titlesbox">
            <div class="content_activityNamebox" v-if="content.activityName">
                {{content.activityName}}
            </div>
            <div class="content_timesbox">
                <div class="content_timesmallbox">
                  <i class="iconfont icon-liulanliang1"></i>
                  <div>{{content.browseTotal}}</div>
                </div>
                <div>
                    {{content.times}}
                </div>
            </div>
        </div>
        <div class="content_xian"></div>
        <article v-html="content.text"></article>
    </div>
</template>

<script>
import {
    postbiologicalScience,
    postbioLife,
    postbioNews,
    postheadlines,
    postmorningNewsBus,
    postindexCarousel,
    posttalentsApartment,
    postepidemicPreventionPolicy,
    noticeDetail
} from '../../api/article'

import { getnoticeIndex } from '../../api/personnel'
export default {
    name: 'articles',
    //文章详情
    components: {

    },
    data() {
        return {
            content:{
                title:"",
                activityName:"",
                times:"",
                browseTotal:"",
                text:""
            }
        }
    },
    created() {
        this.getContent()
    },
    methods: {
        getContent(){
            let fromname = this.$route.query.from
            let serviceID = this.$route.query.serviceID
            if(fromname == "生物科普"){
                postbiologicalScience(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    this.content.activityName = res.data.data.biologicalScienceTypeName
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "BIO生活"){
                postbioLife(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    this.content.activityName = res.data.data.bioLifeTypeIName
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "BIO要闻"){
                postbioNews(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    // this.content.activityName = res.data.data.bioLifeTypeIName
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "资讯头条"){
                postheadlines(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    this.content.activityName = res.data.data.headlinesTypeName
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "新闻早班车"){
                postmorningNewsBus(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "首页轮播"){
                postindexCarousel(serviceID)
                .then(res =>{
                    console.log(res);
                    this.content.title = res.data.data.title
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "人才公寓"){
                posttalentsApartment(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "防疫通知一条"){
                getnoticeIndex(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }
            if(fromname == "防疫政策首页"){
                postepidemicPreventionPolicy(serviceID)
                .then(res =>{
                    this.content.title = res.data.data.title
                    this.content.times = res.data.data.createTime.slice(0,16)
                    this.content.browseTotal = res.data.data.browseTotal
                    this.content.text = res.data.data.content
                })
            }

            if(fromname == 'moreNotice') {
              noticeDetail(serviceID).then(res => {
                this.content.title = res.data.data.title
                this.content.times = res.data.data.createTime.slice(0,16)
                this.content.browseTotal = res.data.data.browseTotal
                this.content.text = res.data.data.content
              })
            }
        }
    },
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";
.content_bigbox {
  padding-bottom: @100px;
}
.content_titletop{
    width: 100%;
    height: @42px;
}
.content_title{
    width: @690px;
    margin-left:@30px;
    font-size: @40px;

    font-weight: bold;
    line-height: @54px;
}
.content_titlesbox{
    margin-top: @29px;
    width: @690px;
    margin-left:@30px;
    // background: rgb(241, 168, 168);
    display: flex;
    justify-content: space-between;
  align-items: center;
}
.content_activityNamebox{
    font-size: @28px;
    color: #00B277;
}
.content_timesbox{
    font-size: @28px;
    color: #666666;
    display: flex;
}
.content_timesmallbox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: @20px;
}
.content_browseTotalsvg{
    width: @26px;
    height: @22px;
    margin-top: @5px;
    margin-right: @3px;
}
.content_xian{
    margin-top: @31px;
    width: 100%;
    height: @10px;
    background: #F8F8F8;
}
article{
  padding: 0 @30px;

  /deep/ p{
    img{
      width: 100%;
    }
  }
  /deep/ a>img{
    width: 100%!important;
  }
  img{
    width: 100%!important;
  }
}
.icon-liulanliang1 {
  font-size: 13px;
  padding-top: 1px;
}

</style>
