function getHeight () {
  let arr = []
  for (let i = 140; i < 240; i++) {
    arr.push(i + ' cm')
  }
  return arr
}

export const politicals = ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众']
export const culturesText = ['小学及以下', '初中', '高中、技校', '中专', '大专', '大学本科', '硕士研究生', '博士研究生']
export const entrys = {
  1: '注册用户',
  2: '会员用户',
  4: '所有用户'
}
export const honorGrades = ['全国级', '省级', '市级', '区县级']
export const marriages = ['已婚', '未婚', '离异']
export const helpTypes = ['生活救助类', '医疗救助类', '职业介绍类', '职业培训类', '法律援助类', '其他']
export const diffTypes = ['低保户', '低保边缘户', '意外致困户']
export const difficultys = ['困难建档职工', '非困难职工', '城镇困难职工', '困难农民工']
export const models = ['非劳模', '全国劳模', '省部级劳模', '地市级劳模', '其他']
export const nativePlaceTypes = ['农业', '非农业']
export const medicares = ['无医保', '城镇职工医保', '城镇（乡）居民医保', '新农合']
export const unionEntry = ['所有用户', '认证会员', '本级及以下工会职工']
export const skills = ['无', '初级工', '中级工', '高级工', '技师', '高级技师', '初级职称', '中级职称', '高级职称']
export const companyPositions = ['董事长', '党委书记', '总经理', '副总经理', '总工程师', '厂长', '副厂长', '部门经理', '车间主任', '职工']
export const grades = ['国家级', '省部级', '地市级', '区级', '其他']
export const employments = ['在岗', '待岗', '失业', '病休', '退休', '退职', '退养（即内退）']
export const nations = ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '傈僳族', '佤族', '畲族', '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族']
export const chineseNums = [
  '零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十',
  '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十',
  '二十一', '二十二', '二十三', '二十四', '二十五', '二十六', '二十七', '二十八', '二十九', '三十',
  '三十一', '三十二', '三十三', '三十四', '三十五', '三十六', '三十七', '三十八', '三十九', '四十',
  '四十一', '四十二', '四十三', '四十四', '四十五', '四十六', '四十七', '四十八', '四十九', '五十'
]
export const weeks = ['零', '一', '二', '三', '四', '五', '六', '日']
export const capital = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
export const numBanner = {
  spaceBetween: 0,
  centeredSlides: true,
  loopAdditionalSlides: 3,
  speed: 500,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    type: 'fraction',
    renderFraction: function (currentClass, totalClass) {
      return '<span class="' + currentClass + '">0</span>' +
        ' / ' +
        '<span class="' + totalClass + '">0</span>'
    }
  },
  loop: true
}
export const defaultTitles = {
  'rongchang': '荣昌区总工会',
  'yilong': '仪陇工会',
  'xichong': '西充县总工会',
  'gaoping': '高坪职工之家',
  'jialing': '嘉陵职工之家',
  'shunqing': '顺庆职工',
  'nanbu': '南部职工之家',
  'pengan': '蓬安工会',
  'langzhong': '阆中市总工会',
  'yingshan': '营山工会之家',
  'huigong': '惠工服务'
}
// 需要获取地理位置的模块
export const needGetPosPath = [
  'welfareHome', // 普惠商城
  'interest', // 兴趣联盟
  'workArt', // 职工文艺
  'unionMap' // 工会地图
]
export const vipPaths = [
  // 会员认证按钮触发
  // 农民工认证按钮触发
  // 工会活动按钮触发
  // 兴趣社区按钮触发
  // 投票按钮触发
  // 投递简历按钮触发
  // 农民工专区建档按钮触发
  // 车票补贴触发登录
  // 农民工专区活动按钮触发
  // 'cadreApply', // 干部认证
  'siginIn', // 每日签到
  'quizAnswer', // 有奖问答答题
  'applyVote', // 投票活动报名
  'welfareOrder', // 抢福利
  'createSingle', // 婚恋完善信息
  'loveChat', // 婚恋聊天
  'addResume', // 添加简历
  'askDoctor', // 资讯医生
  'applyTicket', // 车票申请
  'onlineAskCreate', // 工友之声提问
  'writeQuest', // 填写问卷
  'legalAidApply', // 法律援助触发登录
  'createHonor', // 工会板报发布荣誉触发登录
  'createArt', // 职工文艺发布
  'chooseArtLabel', // 第一次进入职工文艺选择标签
  'creatUnion', // 申请建会
  'sumup' // 年终总结
]
// ====== 首页活动任务 ========
export const TASK_PATH = {
  1: {
    taskPath: ['/signIn']
  },
  2: {
    taskPath: ['/article/details/:id']
  },
  3: {
    taskPath: [
      {path: ['/welfareHome']},
      {path: ['/job']},
      {path: ['/legalAid']},
      {path: ['/workerCollege']},
      {path: ['/unionMap']},
      {path: ['/kind', '/donate', '/volunteer']},
      {path: ['/onlineAsk']},
      {path: ['/love']},
      {path: ['/interest']},
      {path: ['/model']},
      {path: ['/workerArt']}
    ]
  }
}

/**
 * 任务功能
 */
export const TASK_FUN = {
  WALK_DONATE: 1, // 运动捐步
  ASSIST_LOVE: 2, // 爱心捐助
  INTEREST_PICTURE: 3 // 发布兴趣活动图片
}
/**
 * 任务类型（1 签到、2 阅读文章、3 浏览功能模块、4 完成指定任务）
 */
export const TASK_TYPE = {
  SIGN: 1, // ("签到", 1)
  READ_ARTICLE: 2, // ("阅读文章", 2),
  BROWSE_FUNCTION: 3, // ("浏览功能模块", 3),
  FINISH_TASK: 4 // ("完成指定任务", 4)
}
/**
 * 任务模块
 */
export const TASK_MODULE = {
  1: '/welfareHome', // '普惠商城',
  2: '/job', // '求职招聘'
  3: '/legalAid/index', // 法律机构
  4: '/workerCollege', // 职工学院
  5: '/unionMap', // 工会地图,
  6: '/kind', // 工会公益,
  7: '/onlineAsk', // 工友之声
  8: '/love', // 单身联谊
  9: '/interest/index', // 兴趣联盟
  10: '/model/index', // 劳模工匠
  11: '/workerArt'// 职工文艺
}

export const TIME_STATUS = {
  'underway': 'icon-jinhangzhong',
  'noStart': 'icon-weikaishi',
  'end': 'icon-yijieshu'
}
export const pointBanner = {
  spaceBetween: 0,
  centeredSlides: true,
  loopAdditionalSlides: 3,
  loop: true,
  speed: 500,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  }
}
export const pointBannerEgg = {
  spaceBetween: 0,
  centeredSlides: true,
  loopAdditionalSlides: 3,
  loop: false,
  speed: 500,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  }
}
export const pointBannerNoAutoPlay = {
  spaceBetween: 0,
  centeredSlides: true,
  loopAdditionalSlides: 3,
  loop: false,
  speed: 500,
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  }
}
export const defData = {
  uploadUrl (url, module, width = -1, height = -1) {
    let uploadUrl = `${url}/file/upload?module=${module}`
    if (process.env.NODE_ENV === 'development') {
      uploadUrl += '&debug=true'
    }
    return uploadUrl
  },
  // 数字角标轮播
  numberBanner: {
    spaceBetween: 0,
    centeredSlides: true,
    loopAdditionalSlides: 3,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'fraction',
      renderFraction: function (currentClass, totalClass) {
        return '<span class="' + currentClass + '"></span>' +
          ' / ' +
          '<span class="' + totalClass + '"></span>'
      }
    },
    loop: true
  },
  // 点点角标轮播
  pointBanner: {
    // slidesPerView: 1.2,
    spaceBetween: 0,
    centeredSlides: true,
    loopAdditionalSlides: 3,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  },
  page: {
    page: 1,
    pageSize: 10,
    keyParam: {},
    kv: {},
    hasNextPage: true
  },
  welfareType: {
    1: '兑换券',
    2: '折扣券',
    3: '代金券',
    4: '满减券',
    5: '实物券'
  },
  education: ['小学', '初中', '高中', '专科', '本科', '硕士', '博士'],
  height: getHeight(),
  money: ['3千以下', '3千-5千', '5千-8千', '8千-1万', '1万-1.5万', '1.5万-2万', '2万以上'],
  requiremoney: [[0, 3000], [3000, 5000], [5000, 8000], [8000, 10000], [10000, 15000], [15000, 20000], [20000, 21000]],
  codeUrl: process.env.NODE_ENV === 'development' ? '/fwh/system/verifycode?debug=true&openid=oPDWLvxP23nSTJYWQJ51g4_30quI&random=' : '/fwh/system/verifycode?random=',
  molds: ['国有企业', '集体企业', '事业单位', '机关单位', '非公有制内资企业', '外资企业', '参公单位', '其他'],
  industrys: ['农、林、牧、渔业', '采矿业', '制造业', '电力、燃气及水的生产和供应业', '建筑业', '交通运输、仓储及邮政业',
    '信息传输、计算机服务和软件业', '批发和零售业', '住宿和餐饮业', '金融业', '房地产业', '租赁和商业服务业',
    '科学研究、技术服务和地址勘查业', '水利、环境和公共设施管理业', '居民服务和其他服务业', '教育', '卫生、社会保障和社会福利业',
    '文化、体育和娱乐业', '公共管理和社会组织', '国际组织'
  ]
}

export const AD_MAP = {
  '首页顶部轮播广告': 1,
  '签到底部推荐图文广告': 2,
  '首页弹窗广告': 3,
  '签到提醒弹窗广告': 4,
  '签到页面轮播广告': 5,
  '普惠商城轮播广告': 7,
  '工会活动轮播广告': 8,
  '投票评选轮播广告': 9,
  '积分抽奖轮播广告': 10,
  '音乐轮播广告': 11
}
// 接口请求码
export const CODE = {
  '成功': 200,
  '未授权': 401,
  '未登录': 600,
  '未注册': 601,
  '跨平台登录': 701,
  '已冻结': 700,
  '未升级': 702,
  '未关注': 703,
  '登录失败': 704
}
// 图片module
export const UPLOAD_MODULE = {
  '干部认证': 'admin',
  '普惠商城': 'mall',
  '用户': 'users',
  '工友之声': 'oc',
  '投票评选': 'vote',
  '婚恋活动': 'love',
  '法律援助': 'law',
  '农民工专区': 'farmer',
  '困难帮扶': 'diff',
  '工会板报': 'board',
  '职工文艺': 'arts',
  '求职招聘': 'job',
  '申请建会': 'dept',
  '工会活动': 'activity',
  '工会社区': 'forum',
  '兴趣社区': 'interest'
}
export const UPLOAD_MODULE_TYPE = {
  '投票评选': 38
}
// 点击量
export const CLICK_MODULE = {
  '签到阅读文章': 1,
  '首页文章': 2,
  '普惠商城': 3,
  '广告': 4,
  '消息': 5,
  '工会活动': 6,
  '投票': 7,
  '投票项': 8,
  '问卷调查': 9,
  '视频': 10,
  '专题': 11,
  '歌单': 12,
  '工友之声': 13,
  '招聘': 14,
  '公告': 15,
  '视频直播': 16,
  '婚恋活动': 17,
  '兴趣活动': 18,
  '扶持政策': 19,
  '创业案例': 20,
  '市区概况': 21,
  '专场招聘': 22,
  '法律援助': 23,
  '车票赠送': 25,
  '学习资料': 28,
  '学新思想': 29,
  '在线课程': 30,
  '法律讲堂': 31,
  '职工书屋': 32,
  '心理知识': 33,
  '心理测试': 34,
  '减压视频': 35,
  '减压音频': 36,
  '职工风采': 37,
  '荣誉墙': 38,
  '职工文艺': 39,
  '主题活动': 41,
  '工会信息': 42,
  '工会社区': 43,
  '劳动竞赛': 44,
  '年终盘点': 46,
  '劳模馆': 47,
  '劳模故事': 48,
  '创新成果': 49,
  '创新荣誉': 50,
  '劳模讲堂': 51
}
// 工会申请进度状态
export const UNION_STATUS = {
  0: {
    title: '',
    color: ''
  },
  1: {
    title: '建会申请审批中',
    color: '#12C65E'
  },
  2: {
    title: '建会申请审批未通过',
    color: '#FF6633',
    replyText: '重新申请',
    buttonColor: '#12C65E',
    currTitle: '建会申请(待进行)'
  },
  3: {
    title: '建会申请审批已通过',
    color: '#0071FB',
    replyText: '上传会员大会请示',
    buttonColor: '#0071FB',
    currTitle: '上传会员大会请示(待进行)'
  },
  4: {
    title: '会员大会请示审批中',
    color: '#12C65E'
  },
  5: {
    title: '会员大会请示审批未通过',
    color: '#FF6633',
    buttonColor: '#0071FB',
    replyText: '重新上传',
    currTitle: '上传会员大会请示(待进行)'
  },
  6: {
    title: '会员大会请示审批已通过',
    color: '#0071FB',
    replyText: '上传选举结果',
    replyColor: '#0071FB',
    buttonColor: '#0071FB',
    currTitle: '选举结果(待进行)'
  },
  7: {
    title: '选举结果审批中',
    color: '#12C65E'
  },
  8: {
    title: '选举结果审批未通过',
    color: '#FF6633',
    replyText: '重新上传',
    buttonColor: '#12C65E',
    currTitle: '选举结果(待进行)'
  },
  9: {
    title: '建会成功',
    color: '#12C65E'
  }
}
export const cultures = [{text: '小学及以下', value: 1},{text: '初中', value: 2},{text: '高中、技校', value: 3},
    {text: '中专', value: 4},{text: '大专', value: 5},{text: '大学本科', value: 6},{text: '硕士研究生', value: 7},{text: '博士研究生', value: 8}]

export const chineseYearNums = [
  {name: '小于一年', value: 0},
  {name: '一年', value: 1},
  {name: '二年', value: 2},
  {name: '三年', value: 3},
  {name: '四年', value: 4},
  {name: '五年', value: 5},
  {name: '六年', value: 6},
  {name: '七年', value: 7},
  {name: '八年', value: 8},
  {name: '九年', value: 9},
  {name: '十年', value: 10},
  {name: '十一年', value: 11},
  {name: '十二年', value: 12},
  {name: '十三年', value: 13},
  {name: '十四年', value: 14},
  {name: '十五年', value: 15},
  {name: '十六年', value: 16},
  {name: '十七年', value: 17},
  {name: '十八年', value: 18},
  {name: '十九年', value: 19},
  {name: '二十年', value: 20},
  {name: '二十一年', value: 21},
  {name: '二十二年', value: 22},
  {name: '二十三年', value: 23},
  {name: '二十四年', value: 24},
  {name: '二十五年', value: 25},
  {name: '二十六年', value: 26},
  {name: '二十七年', value: 27},
  {name: '二十八年', value: 28},
  {name: '二十九年', value: 29},
  {name: '三十年', value: 30},
  {name: '三十一年', value: 31},
  {name: '三十二年', value: 32},
  {name: '三十三年', value: 33},
  {name: '三十四年', value: 34},
  {name: '三十五年', value: 35},
  {name: '三十六年', value: 36},
  {name: '三十七年', value: 37},
  {name: '三十八年', value: 38},
  {name: '三十九年', value: 39},
  {name: '四十年', value: 40},
  {name: '四十一年', value: 41},
  {name: '四十二年', value: 42},
  {name: '四十三年', value: 43},
  {name: '四十四年', value: 44},
  {name: '四十五年', value: 45},
  {name: '四十六年', value: 46},
  {name: '四十七年', value: 47},
  {name: '四十八年', value: 48},
  {name: '四十九年', value: 49},
  {name: '五十年', value: 50},
  {name: '五十一年', value: 51},
  {name: '五十二年', value: 52},
  {name: '五十三年', value: 53},
  {name: '五十四年', value: 54},
  {name: '五十五年', value: 55},
  {name: '五十六年', value: 56},
  {name: '五十七年', value: 57},
  {name: '五十八年', value: 58},
  {name: '五十九年', value: 59},
  {name: '六十年', value: 60},
  {name: '六十一年', value: 61},
  {name: '六十二年', value: 62},
  {name: '六十三年', value: 63},
  {name: '六十四年', value: 64},
  {name: '六十五年', value: 65},
  {name: '六十六年', value: 66},
  {name: '六十七年', value: 67},
  {name: '六十八年', value: 68},
  {name: '六十九年', value: 69},
  {name: '七十年', value: 70}
]
