import request from '@/utils/request'

// 获取当前正在进行的抽奖, 0 首页， 1 规则页面
export function getLotteryInfoApi(type, drawType, data) {
  return request({
    url: `/wxh5/draw/current/${type}/${drawType}`,
    method: 'get',
    data
  })
}
// 抽奖
export function lotteryApi(drawType, data) {
  return request({
    url: `/wxh5/draw/draw/${drawType}`,
    method: 'get',
    data
  })
}
// 分页查询我的中奖详情
export function getMyLotteryApi(type, data) {
  return request({
    url: `/wxh5/draw/list/prizeMe/${type}`,
    method: 'post',
    data
  })
}
// 获取当前抽奖状态信息
export function getLotteryTypeApi(drawType, data) {
  return request({
    url: `/wxh5/draw/status/${drawType}`,
    method: 'post',
    data
  })
}
// 中奖列表
export function bingoListApi(data) {
  return request({
    url: `/wxh5/draw/list/round`,
    method: 'post',
    data
  })
}
// 中奖列表详情
export function bingoDetailApi(roundID, type, data) {
  return request({
    url: `/wxh5/draw/list/prize/${roundID}/${type}`,
    method: 'post',
    data
  })
}
// 抽奖中奖轮播
export function lotteryNoticeApi(serviceID) {
  return request({
    url: `/wxh5/draw/list/prizeDound/${serviceID}`,
    method: 'post',
  })
}
// 获取抽奖配置
export function getSetApi(serviceID) {
  return request({
    url: `/wxh5/draw/getSet`,
    method: 'get',
  })
}
// 用户当前抽奖轮助力列表
export function assistListApi(data, drawType) {
  return request({
    url: `/wxh5/draw/assistList/${drawType}`,
    method: 'post',
    data
  })
}
// 抽奖轮次包含奖品类型
export function listPrizeTypeApi(roundID) {
  return request({
    url: `/wxh5/draw/listPrize/type/${roundID}`,
    method: 'post',
  })
}


// 集卡首页
export function cardListApi(id) {
  return request({
    url: `/wxh5/cardActivity/details/${id}`,
    method: 'get',
  })
}
// 集卡任务列表
export function cardTaskListApi(id) {
  return request({
    url: `/wxh5/cardActivity/taskList/${id}`,
    method: 'get',
  })
}
// 集卡任务签到
export function signTaskApi(id) {
  return request({
    url: `/wxh5/cardActivity/sign/${id}`,
    method: 'post',
  })
}
// 集卡任务分享接口
export function shareTaskApi(id, shareID) {
  return request({
    url: `/wxh5/cardActivity/assistance/${id}/${shareID}`,
    method: 'get',
  })
}
// 抽卡
export function drawCardApi(id) {
  return request({
    url: `/wxh5/cardActivity/drawCard/${id}`,
    method: 'post',
  })
}
// 合成
export function synthesisApi(id) {
  return request({
    url: `/wxh5/cardActivity/compositeCardBook/${id}`,
    method: 'post',
  })
}
// 抽奖
export function cardLotteryApi(id) {
  return request({
    url: `/wxh5/cardActivity/drawRed/${id}`,
    method: 'post',
  })
}
// 邮寄地址添加
export function deliveryAddressApi(data) {
  return request({
    url: `/wxh5/draw/edit/delivery/address`,
    method: 'post',
    data
  })
}
// 奖品详情
export function drawLogDetails(serviceID) {
  return request({
    url: `/wxh5/draw/drawLogDetails/${serviceID}`,
    method: 'get',
  })
}