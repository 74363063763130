<template>
    <div class="Login_BigBox">
        <SvgIcon class="Logo_Img" iconClass="icon-logo2"></SvgIcon>
        <div class="input_BigBox">
            <SvgIcon class="input_svg" iconClass="icon-shoujihaoma"></SvgIcon>
            <input class="Input_box" placeholder="输入手机号码" type="text">
        </div>
        <div class="input_BigBox">
            <SvgIcon class="input_svg" iconClass="icon-yanzhengma"></SvgIcon>
            <input class="Input_box" placeholder="输入验证码" type="text">
            <div v-if="!VerificationCodetype" @click="clickVerificationCode" class="VerificationCode_Box">发送验证码</div>
            <div v-if="VerificationCodetype" class="VerificationCode_Boxs">{{VerificationCodeNumber}}</div>
        </div>
        <div @click="goHome" class="login_Btn">
            登录
        </div>
        <div class="goRegsiter_Box">
            <span>没有账号?去</span>
            <span @click="GoRegsiter" style="color:#00B277">注册</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            VerificationCodetype: false,//验证码倒计时
            VerificationCodeNumber: 60,//验证码倒计时数字
            times: undefined,//计时器
        }
    },
    methods: {
        clickVerificationCode() {
            //点击开始验证码倒计时
            this.VerificationCodetype = true
            this.VerificationCodeNumber = 60
            this.setTimeoutMethods()
        },
        setTimeoutMethods() {
            //验证码倒计时 计时器
            this.times = setTimeout(() => {
                if (this.VerificationCodeNumber > 0) {
                    this.VerificationCodeNumber--
                    this.setTimeoutMethods()
                } else {
                    clearTimeout(this.times)
                    this.VerificationCodetype = false
                }
            }, 1000);
        },
        GoRegsiter() {
            //清除计时器
            clearTimeout(this.times)
            this.$router.push('/regsiter')
        },
        goHome() {
            this.$router.push('/home')
        }
    }
}
</script>
  
<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.Login_BigBox {
    width: 100%;
    height: 100vh;
    background: url("../../assets/login/loginbg.png") no-repeat center center / cover;
}

.Logo_Img {
    width: @200px;
    height: @182px;
    margin-top: @140px;
    margin-left: 50%;
    transform: translate(-50%);
}

.input_BigBox {
    width: @600px;
    height: @60px;
    margin-top: @100px;
    margin-left: @80px;
    border-bottom: @2px #F0F0F0 solid;
    display: flex;
    align-items: center;
    position: relative;
}

.input_svg {
    width: @36px;
    height: @36px;
    line-height: @60px;
}

.Input_box {
    width: 100%;
    height: @60px;
    margin-left: @27px;
    border: 0 none;
    outline: none;
    background: rgba(0, 0, 0, 0);
    font-size: @32px;
}

input::-webkit-input-placeholder {
    /* 输入框提示文字颜色 */
    color: #AAAAAA;
}

.VerificationCode_Box {
    width: @160px;
    height: @54px;
    border: #CCCCCC @2px solid;
    border-radius: @2px;
    position: absolute;
    right: 0;
    top: -@10px;
    font-size: @26px;
    color: #666666;
    text-align: center;
    line-height: @54px;
}

.VerificationCode_Boxs {
    width: @160px;
    height: @54px;
    border: #00B277 @2px solid;
    border-radius: @2px;
    position: absolute;
    right: 0;
    top: -@10px;
    font-size: @26px;
    color: #00B277;
    text-align: center;
    line-height: @54px;
}

.login_Btn {
    width: @600px;
    height: @90px;
    margin-top: @100px;
    background: #00B277;
    margin-left: 50%;
    transform: translate(-50%);
    border-radius: @50px;
    font-size: @34px;
    color: #FFF;
    text-align: center;
    line-height: @90px;
}

.goRegsiter_Box {
    margin-top: @60px;
    text-align: center;
    font-size: @28px;
}
</style>
