<template>
  <div class="home_content_bigBox">
    <!-- 轮播 -->
    <div v-if="componentstype" class="my_swipe_bigbox">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item @click="mySwipeImgGo(index)" v-for="(item, index) in swipelist" :key='index'>
          <div v-if="item.type == 1" class="my_swipe_mask_box">
            <SvgIcon class="my_swipe_videoSvg" iconClass="icon-play"></SvgIcon>
          </div>
          <img class="my_swipe_img" :src="item.urls" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 轮播图视频 -->
    <videoPlay :home_videoplay_Box="home_videoplay_Box" :videourl="videourl" @close="closeVideoplayBox"></videoPlay>
    <!-- 4宫格 -->
    <div v-if="componentstype" class="homecontent_fourGrids_bigBox">
      <div @click="fourGrids(index, item.path)" class="homecontent_fourGrids_box" v-for="(item, index) in fourGridsList"
        :key="index">
        <img :src="item.url" alt="">
        <div>
          {{ item.title }}
        </div>
      </div>
    </div>
    <!-- BIO指南 -->
    <div v-if="componentstype" class="homecontent_BIO_bigBox">
      <SvgIcon class="homecontent_BIO_leftBox" iconClass="icon-yaowen-06"></SvgIcon>
      <div class="homecontent_BIO_rightBox">
        <van-swipe class="homecontent_BIO_swipebox" vertical :autoplay="3000" :duration="1000" :touchable="false"
          :show-indicators=false>
          <van-swipe-item class="vertical-swipe" v-for="(item, index) in BIOnewslist" :key="index">
            <div @click="BIOgo(index, 1)" class="homecontent_BIO_text">
              {{ item.title }}
            </div>
          </van-swipe-item>
        </van-swipe>
        <van-swipe class="homecontent_BIO_swipeboxs" vertical :autoplay="3000" :duration="1000" :touchable="false"
          :show-indicators=false>
          <van-swipe-item class="vertical-swipe" v-for="(item, index) in BIOnewslists" :key="index">
            <div @click="BIOgo(index, 2)" class="homecontent_BIO_text">
              {{ item.title }}
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div v-if="componentstype" style="width:100%;height:0.05rem;background:#F8F8F8"></div>
    <!-- 新闻早班车 -->
    <div v-if="componentstype">
      <earlynewsbus />
      <div class="homecontent_BIO_xian"></div>
    </div>
    <!-- 打卡 -->
    <div @click="toSign">
      <div class="homecontent_daka">
        <img src="../../assets/home/daka.png" alt="">
      </div>
      <div class="homecontent_BIO_xian"></div>
    </div>
    <!-- 视频直播 -->
    <div v-if="componentstype">
      <livevideo />
    </div>
    <!-- 热门活动 -->
    <div>
      <hotevents />
    </div>
    <!-- 头条新闻 -->
    <div v-if="componentstype">
      <headline @videoUrl="videoURL" />
    </div>

    <!--  弹框  -->
    <van-popup v-model="expecttype" round closeable @click-close-icon="expecttype = false">
      <div class="wrapper">
        <div class="block">
          <img src="../../assets/home/政策.jpg" alt="">
          <p class="tips">长按图片识别</p>
        </div>
      </div>
    </van-popup>
    <!--  科技服务弹框  -->
    <van-popup v-model="scienceShow" round closeable @click-close-icon="scienceShow = false">
      <div class="wrapper">
        <div class="block">
          <img src="../../assets/home/科技服务二维码.png" alt="">
          <p class="tips">长按图片识别</p>
        </div>
      </div>
    </van-popup>
    <!--  城市服务弹框  -->
    <van-popup v-model="cityShow" round closeable @click-close-icon="cityShow = false">
      <div class="wrapper">
        <div class="block" style="padding: 20px;">
          <img src="../../assets/home/城市服务.png" alt="">
          <p class="tips">长按图片识别</p>
        </div>
      </div>
    </van-popup>

    <!-- 导航 -->
    <div v-if="componentstype" class="navber_bottom_box">
      <div class="navber_bottom_smallBox">
        <div @click="clickGoHome">
          <SvgIcon class="navber_bottom_svg" iconClass="icon-shouyes"></SvgIcon>
          <div style="color:rgb(45,178,122)">首页</div>
        </div>
      </div>
      <div class="navber_bottom_smallBox">
        <div @click="clickGoMy">
          <SvgIcon class="navber_bottom_svg" iconClass="icon-woden"></SvgIcon>
          <div>我的</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import earlynewsbus from '../../components/homecontentlist/earlynewsbus.vue'
import livevideo from '../../components/homecontentlist/livevideo.vue'
import hotevents from '../../components/homecontentlist/hotevents.vue'
import BIO from '../../components/homecontentlist/BIO.vue'
import biology from '../../components/homecontentlist/biology.vue'
import headline from '../../components/homecontentlist/headline.vue'
import Recruitment from '../../components/homecontentlist/Recruitment.vue'
import { postindexCarouselAPI, postbioNewsAPI, postCommonAddClick } from '@/api/home'
import videoPlay from '@/components/videoplay/normalIndex'
export default {
  name: 'homeContent',
  // 首页内容
  components: {
    earlynewsbus,
    livevideo,
    hotevents,
    BIO,
    biology,
    headline,
    Recruitment,
    videoPlay
  },
  data() {
    return {
      showPicker: false,
      expecttype: false,//敬请期待 弹窗
      componentstype: false,//登录之后放开组件
      fourGridsList: [//4宫格
        {
          url: require('../../assets/home/政策速递.png'),
          title: '政策速递',
          path: ''
        },
        {
          url: require('../../assets/home/人才服务.png'),
          title: '人才服务',
          path: '/service'
        },
        // {
        //   url: require('../../assets/home/防疫服务.png'),
        //   title: '防疫服务',
        //   path: '/pestilenceService'
        // },
        {
          url: require('../../assets/home/科技服务.png'),
          title: '科技服务',
          path: ''
        },
        {
          url: require('../../assets/home/配套服务.png'),
          title: '城市服务',
          path: ''
        },
        {
          url: require('../../assets/home/园区印象.png'),
          title: '园区印象',
          path: '/parkImpression'
        },
        {
          url: require('../../assets/home/前沿观察.png'),
          title: '前沿观察',
          path: '/biologymore'
        },

      ],
      expectboxURL: '',
      swipelist: [
        // {
        //     url: require('../../assets/home/政务服务.png'),
        //     title: '政务服务'
        // },
      ],
      BIOnewslist: [],
      BIOnewslists: [],
      hometype: true,//是否在首页
      home_videoplay_Box: false,//视频播放盒子
      videourl: '',
      scienceShow: false,
      cityShow: false
    }
  },
  watch: {

  },
  created() {
    // 线上登录
    // this.getWechatCode()
    // 本地直接进
    // this.$toast('测试弹框')
    // this.$dialog.alert({
    //   message: '弹窗内容',
    // });
    this.getusermine()
    // wx.ready(_ => {
    //   wx.getLocation({
    //     type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
    //     success: (res) => {
    //       sessionStorage.setItem('latitude', res.latitude)
    //       sessionStorage.setItem('longitude', res.longitude)
    //     }
    //   });
    // })
  },
  activated() {

  },
  methods: {
    clickGoHome() {
      this.$router.go(0)
    },
    clickGoMy() {
      this.$router.push("/my")
    },
    postindexCarousel() {
      // 请求轮播图
      postindexCarouselAPI()
        .then(res => {
          let newlist = res.data.data || []

          newlist.map(v => {
            let picturearr = [] // 图片地址
            picturearr = JSON.parse(v.picture)
            v.urls = picturearr[0].url
            let videoarr = [] // 视频地址
            if (v.video) {
              videoarr = JSON.parse(v.video)
              v.videos = videoarr.url
            }
          })
          this.swipelist = newlist
        })
    },
    fourGrids(index, path) {
      if (index == 0) {
        this.expecttype = true
      } else if (index == 2) {
        this.scienceShow = true
      } else if (index == 3) {
        this.cityShow = true
      } else {
        this.$router.push(path)
      }
    },
    postbioNews() {
      // BIO 新闻
      postbioNewsAPI()
        .then(res => {
          let newlist = res.data.data || []
          newlist.map((v, index) => {
            if (index % 2 == 0) {
              this.BIOnewslist.push(v)
            } else {
              this.BIOnewslists.push(v)
            }
          })
        })
    },
    closeVideoplayBox() {
      // 关闭播放器
      this.home_videoplay_Box = false
    },
    mySwipeImgGo(index) {
      //记录点击量
      postCommonAddClick('indexCarousel', this.swipelist[index].indexCarouselID)
        .then(res => {
        })


      // 轮播大图跳转外链
      if (this.swipelist[index].videos) {
        // 打开播放器
        this.videourl = this.swipelist[index].videos
        this.home_videoplay_Box = true
      } else {
        if (this.swipelist[index].source == 2) {
          this.$router.push({
            name: 'article',
            query: {
              serviceID: this.swipelist[index].serviceID,
              from: "首页轮播"
            }
          })
          return
        }
        window.location.href = this.swipelist[index].link
      }
    },
    BIOgo(e, number) {
      if (number == 1) {
        postCommonAddClick('bioNews', this.BIOnewslist[e].bioNewsID)
          .then(res => {
            // BIO跳转外链
            if (this.BIOnewslist[e].source == 2) {
              this.$router.push({
                name: 'article',
                query: {
                  serviceID: this.BIOnewslist[e].serviceID,
                  from: "BIO要闻"
                }
              })
              return
            }
            window.location.href = this.BIOnewslist[e].link
          })
      } else {
        postCommonAddClick('bioNews', this.BIOnewslists[e].bioNewsID)
          .then(res => {
            if (this.BIOnewslists[e].source == 2) {
              this.$router.push({
                name: 'article',
                query: {
                  serviceID: this.BIOnewslists[e].serviceID,
                  from: "BIO要闻"
                }
              })
              return
            }
            window.location.href = this.BIOnewslists[e].link
          })

      }


    },

    getusermine() {
      this.postindexCarousel()
      this.postbioNews()
      this.componentstype = true //放开组件
    },
    closeExpect() {
      this.expecttype = false
    },
    videoURL(e) {
      //子组件点击跳转视频
      this.videourl = e
      this.home_videoplay_Box = true
    },
    toSign() {
      this.$router.push('/signIn')
    }
  },

}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.home_content_bigBox {
  width: 100%;
  height: 93vh;
  overflow-y: scroll;
}

.my_swipe_bigbox {
  padding: @30px;
  border-radius: @10px;
  overflow: hidden;
  transform: translateY(0);
}

.my-swipe {
  border-radius: @10px;
  overflow: hidden;
  transform: translateY(0);

  /deep/ .van-swipe__indicator {
    width: @30px !important;
    height: @4px !important;
    border-radius: 0 !important;
  }

  /deep/ .van-swipe__indicator--active {
    width: @60px !important;
  }
}

.van-swipe-item {
  color: #fff;
  width: @700px;
  text-align: center;
  height: @290px;
  position: relative;
  border-radius: @10px;
  overflow: hidden;
  transform: translateY(0);
}

.my_swipe_mask_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: @10px;
  //overflow: hidden;
  //transform: translateY(0);
  z-index: 10;
}

.my_swipe_closeSvgbox {
  width: @100px;
  height: @100px;
  border: #FFF 1px solid;
  border-radius: @100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.my_swipe_videoSvg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: @60px;
  height: @60px;

}

.my_swipe_img {
  width: 100%;
  height: 100%;
  border-radius: @10px;
  object-fit: cover;
  overflow: hidden;
  transform: translateY(0);
}

.home_videoplay_Box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: #000000;
}

.home_video_play_closeSvg {
  width: @40px;
  height: @40px;
  position: fixed;
  top: @40px;
  right: @40px;
  z-index: 10;

}

.my_swipe_img_video {
  width: 100%;
  height: @420px;
  margin-top: 45%;
  object-fit: cover;
  overflow: hidden;
  transform: translateY(0);
}

.homecontent_fourGrids_bigBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #F8F8F8;
  padding: 0 @30px;
}

.homecontent_fourGrids_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: @26px;
  color: #000000;
  padding-bottom: @36px;
  margin-right: @136px;

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.homecontent_fourGrids_box>img {
  width: @80px;
  height: @80px;
  margin-bottom: @20px;
}

.homecontent_BIO_bigBox {
  margin-top: @20px;
  width: @700px;
  height: @150px;
  margin-left: @30px;
  display: flex;
  align-items: center;
}

.homecontent_BIO_leftBox {
  width: @84px;
  height: @84px;
  /* margin-left: @10px; */
  margin-right: @20px;
}

.homecontent_BIO_rightBox {}

.homecontent_BIO_swipebox {
  width: @576px;
  height: @30px;
  // background: #2878FF;
}

.vertical-swipe {
  color: #333333 !important;
  text-align: left !important;
}

.homecontent_BIO_swipeboxs {
  width: @576px;
  height: @30px;
  margin-top: @20px;
  // background: #2878FF;
}

.homecontent_BIO_text {
  width: @576px;
  height: @30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: @30px;
}

.homecontent_BIO_xian {
  width: 100%;
  height: @10px;
  background: #F8F8F8;
  margin-top: @30px;
}

.navber_bottom_box {
  width: 100%;
  height: 7vh;
  display: flex;
  position: fixed;
  bottom: 0;
  background: #fff;
}

.navber_bottom_smallBox {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: @20px;
}

.navber_bottom_svg {
  width: @40px;
  height: @40px;
  margin-left: 50%;
  transform: translate(-50%);
}

.my_expectbigbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.my_expectbox {
  width: @640px;
  // height: @430px;
  background: #FFFFFF;
  border-radius: @20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .tips {
    text-align: center;
  }
}

.my_svg {
  width: @30px;
  height: @30px;
  position: absolute;
  top: @30px;
  right: @30px;
}

.my_expectbox>img {
  margin-top: @50px;
  width: @500px;
  margin-left: 50%;
  transform: translate(-50%);
  margin-bottom: @50px;
}

.my_text {
  margin-top: @30px;
  width: 100%;
  font-size: @36px;
  text-align: center;
  color: #2878FF
}

.my_texts {
  margin-top: @10px;
  width: 100%;
  font-size: @30px;
  text-align: center;
  color: #666666;
}

.homecontent_daka {
  width: @690px;
  margin-left: @30px;
  margin-top: @30px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: @30px @40px;

  .block {
    width: @500px;
    height: auto;
    background-color: #fff;

    .tips {
      font-size: @26px;
      color: #666666;
      text-align: center;
      margin-top: @30px;
    }
  }
}</style>
