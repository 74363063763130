import request from '@/utils/request'

// 用户详情
export  function getuserstatusAPI(){
    return request({
        url:`/wxh5/user/mine`,
        method:'get',
    })
}

//我的红包
export  function useLoglistAPI(data){
    return request({
        url:'/wxh5/wallet/useLog/list',
        method:'post',
        data:data
    })
}

// 我的 活动
export  function myActivitiesAPI(data){
    return request({
        url:'/wxh5/user/myActivities',
        method:'post',
        data:data
    })
}