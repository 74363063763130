<template>
    <div class="livevideomore_bigbox">
        <div class="livevideomore_box">
            <div @click="golivevideomore(index)" class="homecontent_livevideo_contentbox">
                <div>
                    <div v-if="!livevideoContentList.type" class="homecontent_livevideo_label"
                        style="background:#00B277">即将开始</div>
                    <div v-if="livevideoContentList.type" class="homecontent_livevideo_label">直播中</div>
                    <img class="livevideo_content_img" :src="livevideoContentList.url" alt="">
                    <div class="livevideo_content_title">{{livevideoContentList.title}}</div>
                    <div v-if="!livevideoContentList.type" class="livevideo_countdown_box">
                        <div>距离开始:</div>
                        <div v-if="livevideoContentList.day > 0" class="livevideo_countdown_end"
                            style="color:#00B277;border:#00B277 1px solid">{{livevideoContentList.day}}时</div>
                        <div v-if="livevideoContentList.day > 0" style="color:#00B277"> : </div>
                        <div v-if="livevideoContentList.hours > 0" class="livevideo_countdown_end"
                            style="color:#00B277;border:#00B277 1px solid">{{livevideoContentList.hours}}时</div>
                        <div v-if="livevideoContentList.hours > 0" style="color:#00B277"> : </div>
                        <div v-if="livevideoContentList.minutes > 0" class="livevideo_countdown_end"
                            style="color:#00B277;border:#00B277 1px solid">{{livevideoContentList.minutes}}分</div>
                        <div v-if="livevideoContentList.minutes > 0" style="color:#00B277"> : </div>
                        <div class="livevideo_countdown_end" style="color:#00B277;border:#00B277 1px solid">
                            {{livevideoContentList.seconds}}秒</div>
                    </div>
                </div>
            </div>
            <div class="homecontent_BIO_xian"></div>
            <div class="livevideomore_centent_box">
                <div class="livevideomore_centent_titlebox">
                    <div class="livevideomore_centent_xian"></div>
                    <div>直播介绍</div>
                    <div class="livevideomore_centent_xian"></div>
                </div>
                <div v-html="livevideoContentList.description" class="livevideomore_cententbox">

                </div>
            </div>
            <div @click="golink" class="livevideomore_go_box">
                观看直播
            </div>
        </div>
    </div>
</template>

<script>
import { postliveVideodetailsAPI } from '../../api/home'
export default {
    name: 'livevideo',
    // 视频直播
    components: {

    },
    data() {
        return {
            livevideoContent: '',
            livevideoContentList:
            {
                title: '"世界卫生日"生物医药知识普及',
                url: require('../../assets/home/yiyao.png'),
                type: true,
                day: 1,
                hours: 12,
                minutes: 50,
                seconds: 24,
            },
            seTtimeouts:"",

        }
    },
    created() {
        this.postliveVideodetails()
    },
    methods: {
        postliveVideodetails() {
            postliveVideodetailsAPI(this.$route.query.serviceID)
                .then(res => {
                    let list = res.data.data || []
                    let newurl = JSON.parse(list.picture)
                    list.url = newurl[0].url
                    if (list.state == 0) {//处理状态
                        list.type = false
                        // 先计算每条数据的倒计时差值
                        let starttimes = parseInt((new Date(list.startTime).getTime()) / 1000);
                        let newtimes = parseInt((new Date().getTime()) / 1000)
                        list.difftime = starttimes - newtimes
                        this.settimeoutfuntion()
                    } else {
                        list.type = true
                    }
                    this.livevideoContentList = list
                })
        },
        settimeoutfuntion() {
            // //视频未开始倒计时
            let endtime = 0
            if (this.livevideoContentList.state == 0) {
                if (this.livevideoContentList.difftime > 0) {
                    this.livevideoContentList.day = parseInt(this.livevideoContentList.difftime / (60 * 60 * 24))
                    this.livevideoContentList.hours = parseInt(this.livevideoContentList.difftime / (60 * 60) % 24)
                    this.livevideoContentList.minutes = parseInt(this.livevideoContentList.difftime / 60 % 60)
                    this.livevideoContentList.seconds = parseInt(this.livevideoContentList.difftime % 60)
                    this.livevideoContentList.difftime = this.livevideoContentList.difftime - 1
                    this.$forceUpdate()
                } else {
                    endtime = 1
                }
            } 
            if(this.seTtimeouts)  clearTimeout(this.seTtimeouts)
            this.seTtimeouts = setTimeout(() => {
                if (endtime == 0) {
                    this.settimeoutfuntion()
                } else {
                    this.postliveVideo()
                }
            }, 1000);


        },
        golink() {
            if (this.livevideoContentList.link) window.location.href = this.livevideoContentList.link

        }
    },
    beforeDestroy() {
        clearTimeout(this.seTtimeouts)
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.livevideomore_bigbox {
    width: 100%;
    height: 100%;
}

.livevideomore_box {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.homecontent_livevideo_contentbox {
    width: @690px;
    margin-left: @30px;
    position: relative;
    padding-bottom: @20px;
    margin-top: @10px;
}

.homecontent_livevideo_contentbox:last-child {
    border-bottom: 1px solid #FFF;

}

.homecontent_livevideo_label {
    position: absolute;
    top: @40px;
    left: @20px;
    width: @110px;
    height: @44px;
    font-size: @24px;
    background: #EF882C;
    border-radius: @6px;
    color: #FFFFFF;
    text-align: center;
    line-height: @44px;
}

.livevideo_content_img {
    width: 100%;
    height: @290px;
    border-radius: @20px;
    margin-top: @20px;
    object-fit: cover;
}

.livevideo_content_title {
    margin-top: @20px;
    font-size: @34px;
    font-size: @44px;
}

.livevideo_countdown_box {
    width: 100%;
    height: @40px;
    display: flex;
    align-items: center;
    font-size: @24px;
    margin-top: @20px;
}

.livevideo_countdown_end {
    width: @70px;
    height: @36px;
    line-height: @36px;
    color: #EF882C;
    border: #EF882C 1px solid;
    border-radius: @2px;
    text-align: center;
    margin-left: @20px;
    margin-right: @20px;
}

.homecontent_BIO_xian {
    width: 100%;
    height: @10px;
    background: #F8F8F8;
    margin-top: @30px;
}

.livevideomore_centent_box {
    width: 100%;
}

.livevideomore_centent_titlebox {
    width: @240px;
    font-size: @34px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: @40px;
}

.livevideomore_centent_xian {
    width: @40px;
    height: @2px;
    background: #00B277;
}

.livevideomore_cententbox {
    width: @690px;
    margin-left: @30px;
    margin-top: @20px;
    line-height: @60px;
    font-size: @32px;
}

.livevideomore_go_box {
    width: @500px;
    height: @90px;
    font-size: @34px;
    background: #EF882C;
    color: #FFF;
    border-radius: @45px;
    line-height: @90px;
    text-align: center;
    margin: auto;
    margin-top: @100px;
    margin-bottom: @40px;
}
</style>
