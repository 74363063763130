<template>
    <div v-if="contentType" class="content_bigBox">
        <div >
            <div class="content_box">
                <div class="content_titlebox">
                    <div class="content_title">人才招聘</div>
                    <div @click="goServicemore" class="content_righttitle">更多招聘</div>
                </div>
                <div class="content_boxs" v-for="(item,index) in contentList" :key="index" @click.stop="toDetail(item.serviceID, index)">
                    <div class="content_textbox">
                        <div class="content_textleft">
                            {{item.jobName}}
                        </div>
                        <div class="content_textright">
                            {{item.money ?item.money:"面议"}}
                        </div>
                    </div>
                    <div class="content_textbottom">
                        <SvgIcon class="content_svg" iconClass="icon-suoshugongsiquancheng"></SvgIcon>
                        <div class="content_texttype">{{item.companyName}}</div>
                    </div>
                </div>
            </div>
            <div class="centent_xian"></div>
        </div>
    </div>
</template>

<script>
import { postRecruitment } from '../../api/personnel'
import {postCommonAddClick} from '../../api/home'
export default {
    name: 'Recruitment',
    //人才招聘
    components: {

    },
    data() {
        return {
            contentList:[],//列表
            contentType:false,
        }
    },
    methods: {
        postRecruitmentAPI(){
            postRecruitment()
            .then(res =>{
                let list = res.data.data || []
                list.map((v,index) =>{
                    if(v.negotiable == 0){
                        v.money = (v.minSalary*0.001) + '-' + (v.maxSalary*0.001) + "K"
                    }
                    if(index == 0) {
                        this.contentType = true
                        this.contentList.push(v)
                    }
                    if(index == 1) this.contentList.push(v) //最多显示两天
                })

            })
        },
        goServicemore(){
            this.$router.push('/servicemore')
        },
      toDetail(serviceID, index) {
        this.addViews(this.contentList[index].recruitmentID, 'recruitment')
        this.$router.push(`/recruitmore?id=${serviceID}`)
      },
      //  增加阅读量
      addViews(id, key) {
        postCommonAddClick(key, id)
      },
    },
    created() {
        this.postRecruitmentAPI()
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.BIO_bigBox {
    width: 100%;
}

.centent_xian {
    width: 100%;
    height: @10px;
    background: #F8F8F8;
    margin-top: @30px;
}

.content_box {
    padding: @30px;
}

.content_titlebox {
    display: flex;
    justify-content: space-between;
}

.content_title {
    font-size: @34px;
    font-weight: bold;
}

.content_righttitle {
    color: #777777;
    font-size: @26px;
}
.content_boxs{
    width: @690px;
    height: @153px;
    margin-top: @20px;
    background: #FAFAFA;
    padding: @30px;
    border-radius: @10px;
}
.content_textbox{
    width: 100%;
    height: @48px;
    font-size: @34px;
    line-height: @48px;
    display: flex;
    justify-content: space-between;
  align-items: center;

}
.content_textleft{
    max-width: @420px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}
.content_textright{
    color: #EF882C;
}
.content_textbottom{
    width: 100%;
    height: @40px;
    margin-top: @10px;
    // background: #EF882C;
    font-size: @28px;
    display: flex;
    align-items: center;
}
.content_svg{
    width: @30px;
    height: @30px;
}
.content_texttype{
    color: #666666;
    margin-left: @10px;
    line-height: @40px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}
</style>
