import Vue from 'vue'
/**
 * 判断对象是否是空对象
 * @param o
 * @returns {any}
 */
Vue.prototype.$isEmpty = (o) => {
  for (let p in o) {
    return false
  }
  return true
}

Vue.prototype.$isNotEmpty = (o) => {
  return !Vue.prototype.$isEmpty(o)
}

/**
 * 深度clone
 * @param o
 * @returns {any}
 */
Vue.prototype.$clone = (o) => {
  return JSON.parse(JSON.stringify(o))
}

/**
 * 控制台输出变量值
 * @param o
 */
Vue.prototype.$print = (o) => {
  console.log(o)
}
/**
 * 控制台格式化输出变量值
 * @param o
 */
Vue.prototype.$printf = (o) => {
  console.log(JSON.stringify(o, null, 2))
}

/**
 * 下载文件
 * @param href
 */
Vue.prototype.$download = (href) => {
  let a = document.createElement('a')
  let event = new MouseEvent('click')
  a.setAttribute('href', href)
  a.setAttribute('target', '_blank')
  a.dispatchEvent(event)
}

Vue.prototype.$delConfirm = (callback, tip = '此操作将永久删除该数据, 是否继续?') => {
  Vue.prototype.$confirm(tip, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    callback()
  })
}
/** *
 * 按照给定格式，将给定日期转换为给定的格式
 * 具体调用： time = formatDate(new Date(), "yyyy-MM-dd hh:mm:ss");
 * 如：2020-09-15 23:39:44
 */
export function formatDate(date, fmt) {
  date = new Date(date);
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}

Vue.prototype.$formatDate = formatDate
