import Vue from 'vue'
/**
 * 深度clone
 * @param o
 * @returns {any}
 */
Vue.prototype.$clone = (o) => {
  return JSON.parse(JSON.stringify(o))
}

/**
 * 控制台输出变量值
 * @param o
 */
Vue.prototype.$print = (o) => {
  console.log(o)
}
/**
 * 控制台格式化输出变量值
 * @param o
 */
Vue.prototype.$printf = (o) => {
  console.log(JSON.stringify(o, null, 2))
}

/*
*lng1,lat1 第一个经纬度
*lng2，lat2 第二个经纬度
* Number
*/

export function calculateDiscount(lng1,lat1,lng2,lat2) {
  const radLat1 = (lat1 * Math.PI) / 180.0;
  const radLat2 = (lat2 * Math.PI) / 180.0;
  const a = radLat1 - radLat2;
  const b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  let s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    );
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000;
  s = s * 1000;
  if (isNaN(s)) {
    return 0 + "m";
  }
  if (s > 100000) {
    // 大于100Km时
    s = Math.floor((s / 1000) * 100) / 100;
    return s.toFixed() + "km";
  }
  if (s > 1000 && s < 100000) {
    // 大于1000米时
    s = Math.floor((s / 1000) * 100) / 100;
    return s.toFixed(1) + "km";
  }
  // 小于1000米直接返回
  return s.toFixed() + "m";
}

/*
* Lat1 Lung1 表示A点经纬度，Lat2 Lung2 表示B点经纬度； a=Lat1 – Lat2 为两点纬度之差 b=Lung1
* -Lung2 为两点经度之差； 6378.137为地球半径，单位为千米；  计算出来的结果单位为千米。
* 通过经纬度获取距离(单位：千米)
* @param lat1
* @param lng1
* @param lat2
* @param lng2
* @return
*/
export function getDistance(lat1, lng1, lat2, lng2) {
  let EARTH_RADIUS = 6378.137;
  let radLat1 = (lat1 * Math.PI) / 180.0;
  let radLat2 = (lat2 * Math.PI) / 180.0;
  let a = radLat1 - radLat2;
  let b = (lng1* Math.PI) / 180.0 - (lng2* Math.PI)  / 180.0;
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
    Math.cos(radLat1) * Math.cos(radLat2) *
    Math.pow(Math.sin(b / 2), 2)));
  s = s * EARTH_RADIUS;
  //s = Math.round(s * 10000d) / 10000d;
  s = Math.round(s * 10000) / 10000 + 'km';
  //s = s * 1000; //乘以1000是换算成米
  return s;
}
