import request from '@/utils/request'
// get
// export function getAAA(){
//     return request({
//         url:"",
//         method:'get',
//     })
// }
// // post
// export function postAAA(){
//     return request({
//         url:"",
//         method:'post',
//         data:data
//     })
// }
//首页轮播
export function postindexCarouselAPI() {
    return request({
        url: 'wxh5/indexCarousel/list',
        method: 'post',
    })
}
//首页 BIO 新闻
export function postbioNewsAPI() {
    return request({
        url: '/wxh5/bioNews/index',
        method: 'post',
    })
}
// BIO 生活管理 横向轮播
export function postbioNbioLifeTypeAPI() {
    return request({
        url: '/wxh5/bioLifeType/list',
        method: 'post',
    })
}
// BIO 生活管理 横向轮播 详情列表
export function postbioLifelistAPI(data) {
    return request({
        url: 'wxh5/bioLife/list',
        method: 'post',
        data: data
    })
}
// BIO 首页 生活管理
export function postbioLifeAPI() {
    return request({
        url: '/wxh5/bioLife/index',
        method: 'post',
    })
}
// 生物科普 首页
export function postbiologicalScienceAPI() {
    return request({
        url: '/wxh5/biologicalScience/index',
        method: 'post',
    })
}
// 生物科普 更多 列表
export function postbiologicalSciencelistAPI(data) {
    return request({
        url: '/wxh5/biologicalScience/list',
        method: 'post',
        data: data
    })
}
// 生物科普 获取分类
export function postbiologicalScienceTypelistAPI() {
    return request({
        url: '/wxh5/biologicalScienceType/list',
        method: 'post',
    })
}
// 新闻早班车 首页
export function postmorningNewsBusAPI() {
    return request({
        url: '/wxh5/morningNewsBus/index',
        method: 'post',
    })
}
// 新闻早班车 列表
export function postmorningNewsBuslistAPI(data) {
    return request({
        url: '/wxh5/morningNewsBus/list',
        method: 'post',
        data: data
    })
}
// 视频直播
export function postliveVideoAPI() {
    return request({
        url: '/wxh5/liveVideo/index',
        method: 'post',
    })
}
// 视频直播 详情
export function postliveVideodetailsAPI(serviceID) {
    return request({
        url: `/wxh5/liveVideo/details/${serviceID}`,
        method: 'get',
    })
}
// 资讯头条 首页列表
export function postheadlinesAPI() {
    return request({
        url: '/wxh5/headlines/index',
        method: 'post',
    })
}
// 资讯头条 获取分类
export function postheadlinesTypeAPI() {
    return request({
        url: '/wxh5/headlinesType/list',
        method: 'post',
    })
}
// 资讯头条 更多 列表
export function postheadlineslistAPI(data) {
    return request({
        url: 'wxh5/headlines/list',
        method: 'post',
        data: data
    })
}
// 首页 活动
export function postindexActivitiesAPI(data) {
    return request({
        url: '/wxh5/activity/center/list',
        method: 'post',
        data
    })
}
// 更多 活动
export function postindexActivitieslistAPI(data) {
    return request({
        url: '/wxh5/indexActivities/list',
        method: 'post',
        data: data
    })
}
// 点击 浏览量 传后台
export function postCommonAddClick(serviceCode, objID) {
    return request({
        url: `/common/addClick?serviceCode=${serviceCode}&objID=${objID}`,
        method: 'post',
    })
}

//
export function getWXapi() {
    return request({
        url: `/wechat/jsapi?serviceCode=bio`,
        method: 'get',
    })
}
const url1 = (window.location.href.split('#')[0]);

export function wxConfigApi() {
    return request({
        url: `wx/get\_signature?url=${url1}`,
        method: 'get',
    })
}