import request from '@/utils/request'
import fetch from '@/api/fetch'

// 获取问题
export  function getQuestionApi(serviceID){
  return request({
    url:`/wxh5/qoManage/answer/${serviceID}`,
    method:'post'
  })
}

// 获取列表
export  function getQuestionList(data){
  return request({
    url:`/wxh5/qoManage/list`,
    method:'post',
    data
  })
}

// 提交答案
export  function submitAnswerApi(data){
  return request({
    url:`/wxh5/qoManage/submitAnswer`,
    method:'post',
    data
  })
}

// 获取下一题
export  function getNextQuestApi(serviceID){
  return request({
    url:`/wxh5/qoManage/getQuestion/${serviceID}`,
    method:'post'
  })
}
// 本轮答题扣积分
export  function startAnswerApi(data){
  return request({
    url:`/wxh5/qoManage/payScore`,
    method:'post',
    data
  })
}

// 有奖问答排名
export  function getQuizRankApi(data){
  return request({
    url:`/wxh5/qoManage/getRankList`,
    method:'post',
    data
  })
}

// 分页查期数
export const getTimeNumApi = (data) => {
  return fetch('post', `/qoManage/getPeriodsList`, data)
}
// 我的排名
export const getMyRankApi = (serviceID) => {
  return fetch('post', `/qoManage/getRankByUser/${serviceID}`)
}
// 分页查询每期奖励
export const getTimeAwardApi = (data) => {
  return fetch('post', `/qoManage/getResultList`, data)
}
// 查看结果
export  function readResultApi(serviceID){
  return request({
    url:`/wxh5/qoManage/answerResult/${serviceID}`,
    method:'post'
  })
}

// 获取规则
export  function getQuizGameRule(serviceID){
  return request({
    url:`/wxh5/qoManage/selectOne/${serviceID}`,
    method:'post'
  })
}

// 是否答题
export  function isAnswerApi(serviceID){
  return request({
    url:`/wxh5/qoManage/answerStatus/${serviceID}`,
    method:'post'
  })
}

// get/reward 获取排名奖励
export const getReward = (serviceID) => {
  return fetch('post', `/qoManage/rankAward/${serviceID}`)
}
// 分页查询我每期排名奖励
export const getMyQuizPrize = (data) => {
  return fetch('post', `/qoManage/getAwardsLog`, data)
}
// 获取用户指定主题的道具卡(需传主题serviceID)
export const getPropsCardForMe = (serviceID) => {
  return fetch('post', `/qoManage/getPropsCardForMe/${serviceID}`)
}
// 道具卡兑换（serviceID问答主题serviceID， cardType需要兑换的道具卡类型）
export const exchangePropsCard = (serviceID, type) => {
  return fetch('post', `/qoManage/exchangePropsCard/${serviceID}/${type}`)
}

// 用户使用换题卡(serviceID问答主题serviceID, answerServiceID当前答题记录的serviceID)
export const useChangeTopicCardApi = (serviceID, answerServiceID) => {
  return fetch('post', `/qoManage/useChangeTopicCard/${serviceID}/${answerServiceID}`)
}
// 用户使用跳题卡(serviceID问答主题serviceID, answerServiceID当前答题记录的serviceID)
export const useJumpTopicCard = (serviceID, answerServiceID) => {
  return fetch('post', `/qoManage/useJumpTopicCard/${serviceID}/${answerServiceID}`)
}
// 用户使用跳关卡(serviceID问答主题serviceID, levelServiceID当前关卡的serviceID)
export const useJumpLevelCard = (serviceID, answerServiceID) => {
  return fetch('post', `/qoManage/useJumpLevelCard/${serviceID}/${answerServiceID}`)
}
// 用户使用复活卡(serviceID问答主题serviceID, answerServiceID当前答题记录的serviceID)
export const useReviveCard = (serviceID, answerServiceID) => {
  return fetch('post', `/qoManage/useReviveCard/${serviceID}/${answerServiceID}`)
}
// 助力记录列表（serviceID问答主题serviceID）
export const assistLog = (serviceID) => {
  return fetch('post', `/qoManage/assistLog/${serviceID}`)
}
// 获取用户指定主题的通过助力获得的道具卡(需传主题serviceID)
export const getPropsCardByAssist = (serviceID) => {
  return fetch('post', `/qoManage/getPropsCardByAssist/${serviceID}`)
}
// 助力（serviceID问答主题serviceID,usersServiceID被助力用户serviceID）
export const assist = (serviceID, usersServiceID) => {
  return fetch('post', `/qoManage/assist/${serviceID}/${usersServiceID}`)
}
// 分页查期数
export const getMyTimeNumApi = (data) => {
  return fetch('post', `/qoManage/getJoinPeriodsList`, data)
}
