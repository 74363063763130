<template>
  <div>
    <!--  视频播放  -->
    <div v-if="home_videoplay_Box" class="home_videoplay_Box">
      <div @click="closeVideoplayBox">
        <SvgIcon class="home_videoplay_closeSvg" iconClass="icon-guanbi"></SvgIcon>
      </div>
      <video controls autoplay webkit-playsinlin="true" playsinline="true" x5-video-player-type="h5"
             x5-video-player-fullscreen="true" class="my_swipe_img" :src="videourl">
      </video>
    </div>
  </div>

</template>

<script>
export default {
  name: "normalVideoPlay",
  props: {
    home_videoplay_Box: {
      type: Boolean,
      default: false
    },
    videourl: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.videoVisiable = this.home_videoplay_Box
  },
  data() {
    return {
      videoVisiable: false
    }
  },
  methods: {
    closeVideoplayBox() {
      // 关闭播放器
      // eslint-disable-next-line
      this.videoVisiable = false
      this.$emit('close')
    },
  },

}
</script>

<style scoped lang="less">
@import "../../assets/css/pixel/pixel";
.home_videoplay_Box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #000
}
.home_videoplay_closeSvg {
  width: @40px;
  height: @40px;
  position: fixed;
  top: @40px;
  right: @40px;
  z-index: 10;
}
.my_swipe_img {
  width: 100%;
  height: auto;
  margin-top: 46%;
  object-fit: fill;
  overflow: hidden;
  transform: translateY(0);
}
</style>
