<template>
  <div>
    <van-popup v-model="show" @click-overlay="clickOverlay">
      <div class="myMail">
        <div class="myTitle">{{exchangeType?'填写联系方式':'填写邮寄地址'}}</div>
        <div class="myTxt" v-if="exchangeType == 0">请认真填写邮寄地址信息，提交后无法更改！</div>
        <van-form @submit="onSubmit">
          <van-field v-model="form.consignee" name="收货人" label="收货人" placeholder="请输入" :border="false"
            :rules="[{ required: true, message: '请输入' }]" />
          <van-field  v-model="form.consigneePhone" type="tel" name="手机号码" label="手机号码" placeholder="请输入" maxlength="11"
            :rules="[{ required: true, message: '请输入', pattern: phonePattern }]" :border="false" />
          <van-field v-if="exchangeType == 0" v-model="areaName" is-link readonly name="所在地区" label="所在地区" placeholder="请选择"
            :rules="[{ required: true, message: '请选择'}]" :border="false" @click="showPicker = true" />

          <van-field v-if="exchangeType == 0" v-model="form.detailAddress" type="textarea" name="详细地址" label="详细地址" placeholder="详细地址" rows="4"
            :rules="[{ required: true, message: '请输入' }]" :border="false" />
          <div class="sub-btn">
            <van-button block type="info" color="#EF882C" native-type="submit">提交</van-button>
          </div>
        </van-form>
        <div class="close" @click="closePopu">X</div>
      </div>
    </van-popup>
    <van-popup style="width: 100%;" v-model="showPicker" position="bottom">
      <van-area v-model="form.areaCode" title="区域" :area-list="areaList" @confirm="confirm"
        @cancel="showPicker = false" />
    </van-popup>
  </div>
</template>
<script>
  import { areaList } from '@vant/area-data';
  import {
    deliveryAddressApi
  } from '@/api/lottery/index'
  export default {
    props: ['serviceID', 'showWindow', 'formInfo','exchangeType'],
    data() {
      return {
        areaList: areaList,
        form: {},
        show: false,
        showPicker: false,
        phonePattern: /^1[3-9]\d{9}$/,
        areaName: null,
      }
    },
    watch: {
      showWindow(value, oldValue) {
        this.show = this.showWindow
        console.log(this.formInfo, this.serviceID, "formInfo")
        if (this.formInfo.userAddressFlag) {
          this.form = this.formInfo
          this.areaName = `${this.form.province}${this.form.city}${this.form.district}`
        } else {
          this.form = {}
          this.areaName = null
          this.form.serviceID = this.serviceID
        }
      }
    },
    methods: {
      // 关闭按钮
      closePopu() {
        this.show = false
        this.$emit('colse', true)
      },
      // 提交邮寄地址
      onSubmit() {
        deliveryAddressApi(this.form).then(res => {
          if (res.data.code == 200) {
            this.show = false
            this.$emit('onSubmit', true)
          }
          console.log(res, "deliveryAddressApi")
        })
      },
      clickOverlay() {
        this.$emit('colse', true)
      },
      // 地区选择
      confirm(e) {
        console.log(e)
        this.form.province = e[0].name
        this.form.city = e[1].name
        this.form.district = e[2].name
        this.form.areaCode = e[2].code
        this.areaName = `${e[0].name}${e[1].name}${e[2].name}`
        this.showPicker = false
      },
    }
  }
</script>
<style lang="less" scoped>
  @import "@/assets/css/pixel/pixel.less";
  @import "@/assets/css/minix/minix.less";

  :deep(.van-popup) {
    width: 90%;
    border-radius: @20px;
  }

  .myMail {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;
    padding: @40px;
    background: #FFFFFF;
    border-radius: @20px;

    .close {
      position: absolute;
      right: @20px;
      top: @20px;
      font-size: @40px;
    }

    .myTitle {
      font-family: PingFang SC;
      font-weight: 800;
      font-size: @36px;
      color: #000000;
      margin-bottom: @30px;
    }

    .myTxt {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: @26px;
      color: #FF6633;
      margin-bottom: @10px;
    }

    :deep(.van-cell__right-icon) {
      position: absolute;
      top: @10px;
      right: 0;
    }

    :deep(.van-form) {
      width: 100%;
    }

    :deep(.van-field__label) {
      width: @120px;
      text-align: right;
    }

    :deep(.van-field__body) {
      padding-left: @20px;
      min-height: @70px;
      border-radius: @6px;
      border: 1px solid #CCCCCC;
    }

    :deep(.van-cell) {
      padding: 0;
      margin-top: @30px;
    }

    .sub-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: @40px;

      :deep(.van-button) {
        width: @360px;
        height: @70px;
        background: #FF6633;
        border-radius: @35px;
        font-family: PingFang SC;
        font-weight: 800;
        font-size: @30px;
        color: #FFFFFF;
      }
    }
  }
</style>