<template>
  <div class="myactivity_bigbox">
    <van-tabs swipe-threshold="1" @click="clicktab" class="myactivity_tab_box" v-model="active">
      <van-tab title="全部"></van-tab>
      <van-tab title="红包"></van-tab>
      <van-tab title="实物"></van-tab>
      <!-- <van-tab title="积分"></van-tab>
      <van-tab title="优惠价"></van-tab> -->
    </van-tabs>
    <div v-for="(item,index) in contentlist" :key="index" class="myactivity_content_bigbox" v-show="active<2">
      <div class="myactivity_content_box">
        <img :src="item.url" alt="">
        <div class="myactivity_content_smallbox">
          <div class="content_smallbox_title">
            {{item.moduleName}}
          </div>
          <div class="content_smallbox_times">
            中奖时间: {{item.createTime}}
          </div>
          <!-- <div v-if="item.exchange == 2" class="content_smallbox_times">
            兑换时间: {{item.endtime}}
          </div>
          <div v-if="item.exchange == 3" class="content_smallbox_times">
            过期时间: {{item.endtime}}
          </div> -->
        </div>
        <!-- 未兑换 -->
        <div class="myactivity_content_rightbox">
          <!-- 4种奖品  -->
          <div class="content_rightbox_title">系统发放</div>
          <!-- <div v-if="item.type == 2" class="content_rightbox_title">统一邮寄</div>
          <div v-if="item.type == 3" class="content_rightbox_title">系统发放</div>
          <div v-if="item.type == 4" class="content_rightbox_title">扫码兑换</div> -->
          <!-- 三种情况 1未兑换 2已兑换 3已过期 -->
          <!-- <div v-if="item.exchange == 1" class="content_rightbox_titlebox" style="background:#FF6600">去兑换</div>
          <div v-if="item.exchange == 2" class="content_rightbox_titlebox">已兑换</div>
          <div v-if="item.exchange == 3" class="content_rightbox_titlebox" style="background:#AAAAAA">已过期</div> -->
        </div>
      </div>
      <div class="myactivity_content_xian"></div>
    </div>
    <m-scroll ref="mscroll" :getData='getMyLotteryFn' emptyText='暂无中奖' v-model="pages" v-show="active==2">
      <ul class="prizes">
        <li class="vux-1px-b"
        :class="{hasGet:(item.exchangeTime&&type!==6) || item.expired === '已过期'}"
        v-for="(item, i) in lotterylist" :key="i" @click="detailShow(item)">
          <!-- <p class="title" v-if="!i || (myList[i - 1].roundNum !== myList[i].roundNum)">{{item.drawType?'第'+item.roundNum+'轮积分抽奖砸金蛋':'第'+item.roundNum+'轮积分抽奖'}}</p> -->
          <!-- 实物 -->
          <div class="prize">
            <div class="img" @click.stop="preview([item.type == 1?item.prizePicture:item.fileHostName + item.prizePicture])">
              <img v-if="item.type === 4" src="../../assets/images/benefitWelfare/lottery/money.png" alt="">
              <img v-else-if="item.type === 5" src="../../assets/images/benefitWelfare/lottery/ticket.png" alt="">
              <img v-else-if="item.type === 6" src="../../assets/images/benefitWelfare/lottery/useTicket.png" alt="">
              <img v-else-if="item.type == 1" :src="item.prizePicture" alt="">
              <img v-else :src="item.fileHostName + item.prizePicture" alt="">
              <span v-if="item.expired === '已过期'" class="outTime">已过期</span>
              <span v-else-if="item.exchangeType == 1 || item.exchangeType == 0">{{item.exchangeTime ? '已' : '未'}}领取</span>
              <span v-else-if="item.exchangeType == 2">{{exchangeStatus[item.exchangeStatus]}}</span>
            </div>
            <div class="prizeInfo">
              <p><span>奖品名称：</span>{{item.prizeName}}</p>
              <p class="time"><span>中奖时间：</span>{{dateFilter(item.createTime, 'YYYY-MM-DD HH:mm')}}</p>
              <p v-if="type!==6 && item.exchangeType != 0"><span>兑换截止日期：</span>{{dateFilter(item.exchangeTimeLimit)}}</p>
              <p v-if="item.exchangeType > 0 && item.exchangeTime&&type!==6" class="getTime">
                <span>{{item.exchangeType == 1 ? '兑换' : '发放'}}时间：</span>{{dateFilter(item.exchangeTime, 'YYYY-MM-DD HH:mm')}}
              </p>
              <!-- <p v-if="item.type == 5"><span>奖品券码：</span>{{item.remark}}</p> -->
            </div>
            <p>
              <span class="exchangeType" :class="item.expired !== '已过期' ? `type_${item.exchangeType}` : 'type_1'">{{exchangeTypes[item.exchangeType]}}</span>
              <a b-fb v-if="item.type ==5 && item.prizeLink"
                :href="`${item.prizeLink}?phone=${loginStatus.phone}`"
                class="goUse">查看/使用<i class="iconfont icon-arrow-right"></i>
              </a>
              <a b-fb v-if="item.type ==6 && item.prizeLink"
                href="javascript:;"
                style="color:#ff6633;"
                class="goUse type_3">查看
              </a>
            </p>
          </div>
          <!-- 积分 -->
          <!-- <div v-if="type == 2" class="score">
            <span>获得{{item.score}}积分</span>
            <span class="time">{{dateFilter(item.createTime, 'YYYY-MM-DD HH:mm')}}</span>
          </div> -->
        </li>
      </ul>
    </m-scroll>
    <!--奖品详情-->
    <awardPopup ref='awardPopup' v-model="show" title='奖品详情'>
          <div class="prizeImg">
            <img class="img"  :src="detail.prizePicture" />
          </div>
          <p class="vux-1px-b">{{detail.prizeName}}</p>
          <ul class="info">
            <!-- <li><span>剩余数量：</span><span>{{detail.surplusPrize}}/{{detail.total}}份</span></li> -->
            <li v-if="detail.showPrice == 0"><span>奖品价值：</span><span>{{detail.prizePrice}}元</span></li>
            <!-- <li><span>领取方式：</span><span>{{exchangeTypesTop[detail.exchangeType]}}</span></li> -->
            <li v-if="detail.timeLimit"><span>兑奖时效：</span><span>中奖后{{detail.timeLimit}}天内领奖</span></li>
            <li v-if="detail.prizeSponsor"><span>奖品赞助：</span><span>{{detail.prizeSponsor}}</span></li>
            <!-- <li v-if="detail.prizeLink && type == 5" class="prizeLink"><span>链接地址：</span><span b-fb><a
                  :href="detail.prizeLink">{{detail.prizeLink}}</a></span></li> -->
            <li class="prizeInfo" v-if="detail.prizeInfo"><span>奖品描述：</span>
              <article v-html="detail.prizeInfo"></article>
            </li>
            <li v-if="(type == 1)&&detail.exchangeType"><span>兑换地址：</span>
              <article>{{detail.useAddress}}</article>
            </li>
            <li v-if="detail.userAddressFlag == 1 && detail.exchangeType == 0"><span>邮寄地址:</span><br>
              <div>{{detail.yAddress}}</div>
            </li>
            <div v-if="detail.exchangeType == 0 && detail.exchangeStatus == 0" class="adrBtn" @click="handleBtn">
              {{detail.userAddressFlag?'编辑邮寄地址':'填写邮寄地址'}}</div>
             <div v-if="detail.exchangeType == 1 && detail.exchangeStatus == 0" class="adrBtn" @click="handleBtn">
                {{detail.userAddressFlag?'编辑联系方式':'填写联系方式'}}</div>
          </ul>
    </awardPopup>
    <adressPopup :serviceID="adressDetail.serviceID" :exchangeType="detail.exchangeType" :formInfo="adressDetail" :showWindow="showWindow" @colse="showWindow = false" @onSubmit="submit"  />
  </div>
</template>

<script>
  import { useLoglistAPI } from '../../api/my'
  import { getMyLotteryApi, drawLogDetails } from '@/api/lottery'
  import { dateFilter } from '@/mixins/filters'
  import awardPopup from '../components/awardPopup'
  import adressPopup from '@/components/form/adress/index'
  export default {
    name: 'myactivity',
    //我的礼物
    components: {
      awardPopup,
      adressPopup
    },
    data() {
      return {
        dateFilter,
        type:1,
        showWindow:false,
        show:false,
        detail:{},
        adressDetail:{},
        pages: {
          page: 1,
          pageSize: 20,
          hasNextPage: true
        },
        exchangeTypes: ['统一邮寄', '现场领取'],
        active: 0,//tab标签页
        contentlist: [],
        lotterylist: [],
        contentlists: [
          {
            url: require('../../assets/home/hongbao.png'),
            title: '10元红包',
            starttime: '2022-06-07 10:10',
            endtime: '2022-06-07 10:10',
            type: 1, // 1红包 2实物 3积分 4优惠价
            exchange: 1,//1未领 2领取 3过期
          },
          {
            url: require('../../assets/home/hongbao.png'),
            title: '10元红包',
            starttime: '2022-06-07 10:10',
            endtime: '2022-06-07 10:10',
            type: 1,
            exchange: 2,
          },
          // {
          //   url: require('../../assets/home/BIO6.png'),
          //   title: '蓝牙音响蓝牙音响蓝牙音响',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 2, // 1红包 2实物 3积分 4优惠价
          //   exchange: 1,//1未领 2领取 3过期
          // },
          // {
          //   url: require('../../assets/home/BIO6.png'),
          //   title: '蓝牙音响蓝牙音响蓝牙音响',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 2,
          //   exchange: 2,
          // },
          // {
          //   url: require('../../assets/home/BIO6.png'),
          //   title: '蓝牙音响蓝牙音响蓝牙音响',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 2,
          //   exchange: 3,
          // },
          // {
          //   url: require('../../assets/home/BIO3.png'),
          //   title: '10积分',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 3, // 1红包 2实物 3积分 4优惠价
          //   exchange: 1,//1未领 2领取 3过期
          // },
          // {
          //   url: require('../../assets/home/BIO3.png'),
          //   title: '10积分',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 3,
          //   exchange: 2,
          // },
          // {
          //   url: require('../../assets/home/BIO2.png'),
          //   title: '罗森10元优惠劵',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 4, // 1红包 2实物 3积分 4优惠价
          //   exchange: 1,//1未领 2领取 3过期
          // },
          // {
          //   url: require('../../assets/home/BIO2.png'),
          //   title: '罗森10元优惠劵',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 4,
          //   exchange: 2,
          // },
          // {
          //   url: require('../../assets/home/BIO2.png'),
          //   title: '罗森10元优惠劵',
          //   starttime: '2022-06-07 10:10',
          //   endtime: '2022-06-07 10:10',
          //   type: 4,
          //   exchange: 3,
          // }
        ],
      }
    },
    created() {
      this.useLoglist()
    },
    methods: {
      submit() {
        drawLogDetails(this.detail.serviceID).then(res => {
          this.showWindow = false
          this.detail = res.data.data.drawPrize
          this.adressDetail = res.data.data
          this.detail.exchangeStatus = res.data.data.exchangeStatus
          this.detail.userAddressFlag = res.data.data.userAddressFlag
          this.detail.serviceID = res.data.data.serviceID
           if (this.detail.exchangeType == 0) {
            this.detail.yAddress = `${res.data.data.province}${res.data.data.city}${res.data.data.district}${res.data.data.detailAddress}`
          }
          console.log(res)
        })
      },
      handleBtn(){
        this.showWindow = true
      },
     detailShow(item) {
       drawLogDetails(item.serviceID).then(res => {
         this.show = true
         this.detail = res.data.data.drawPrize
         this.adressDetail = res.data.data
         this.detail.exchangeStatus = res.data.data.exchangeStatus
         this.detail.userAddressFlag = res.data.data.userAddressFlag
         this.detail.serviceID = res.data.data.serviceID
          if (this.detail.exchangeType == 0) {
           this.detail.yAddress = `${res.data.data.province}${res.data.data.city}${res.data.data.district}${res.data.data.detailAddress}`
         }
       })
      },
      clicktab(e) {
        console.log(e, "getMyLotteryApi")
        this.contentlist = []
        if (e < 2) {
          this.useLoglist()
        } else {
          this.lotterylist = []
          this.getMyLotteryFn()
        }
      },
      getMyLotteryFn() {
        if (this.pages.page === 1) {
          this.lotterylist = []
        }
        getMyLotteryApi(1, this.pages).then(res => {
          this.lotterylist = [...this.lotterylist, ...res.data.data.list]
          this.$refs.mscroll.endSuccess(res.data.data)
          // console.log(res, "getMyLotteryApi")
        })
      },
      useLoglist() {
        let data = {
          kv: {},
          page: 0,
          pageSize: 10
        }
        useLoglistAPI(data)
          .then(res => {
            let list = res.data.data.list || []
            list.map(v => {
              v.url = require('../../assets/home/hongbao.png')
            })
            this.contentlist = list
          })
      }
    }


  }
</script>

<style lang="less" scoped>
  @import "../../assets/css/pixel/pixel";
  @import '../../assets/css/minix/minix.less';
  .myactivity_bigbox {
    width: 100%;
  }

  .myactivity_tab_box {
    width: 80%;
    margin-left: 10%;
    margin-top: @20px;
  }

  /deep/ .van-tabs__line {
    background: #EF882C;
  }

  /deep/.van-tab--active {
    font-weight: bold;
    font-size: @34px;
  }

  .myactivity_content_bigbox {
    width: 100%;
  }

  .myactivity_content_box {
    width: 100%;
    margin-top: @30px;
    display: flex;
    align-items: center;
  }

  .myactivity_content_box>img {
    width: @180px;
    height: @135px;
    margin-left: @30px;

  }

  .myactivity_content_smallbox {
    width: @368px;
    height: 100%;
    margin-left: @20px;

  }

  .content_smallbox_title {
    width: @360px;
    height: @48px;
    font-size: @34px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .content_smallbox_times {
    font-size: @24px;
    color: #777777;
    margin-top: @6px;
  }

  .myactivity_content_rightbox {
    width: @120px;
    height: 100%;
  }

  .content_rightbox_title {
    font-size: @26px;
    color: #EF882C;
    text-align: center;
  }

  .content_rightbox_titlebox {
    width: @120px;
    height: @50px;
    background: rgba(255, 153, 0, 0.5);
    border-radius: @26px;
    color: #fff;
    text-align: center;
    line-height: @50px;
    margin-top: @10px;
  }

  .myactivity_content_xian {
    margin-top: @30px;
    width: 100%;
    height: @10px;
    background: rgba(248, 248, 248, 1);
  }
      .prizes{
      position: relative;
      background: none;
      li{
        font-size: @28px;
        background-color: #fff;
        &.hasGet{
          .title,.prize .img,.prize .prizeInfo,.exchangeType{
            filter: grayscale(100%);
          }
          .prize>.prizeInfo>p{
            margin-bottom: @4px;
          }
        }
        .title{
          background:#f8f8f8;
          padding: @25px @30px;
          font-size:@24px;
          color:#929292;
          line-height:@24px;
        }
        >div{
          display: flex;
          padding: @20px @24px;
          &.score{
            font-size: @28px;
            color: #333;
            padding: @24px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .time{
              font-size: @26px;
              color: #aaa;
            }
          }
          &.prize{
            .img,img{
              width: @100px;
              height: @100px;
              border-radius: @6px;
              margin-right: @24px;
              position: relative;
              overflow: hidden;
              span{
                position: absolute;
                bottom: 0;
                text-align: center;
                font-size: @22px;
                width: 100%;
                left: 0;
                color: #fff;
                height: @28px;
                line-height: @34px;
                background: #ff6633;
                &.outTime{
                  background: #aaa;
                }
              }
            }
            .prizeInfo{
              font-size: @28px;
              color: #333;
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
              position: relative;
              top: @2px;
              p{
                user-select: text;
                margin: @6px 0;
                line-height: 1.5;
                position: relative;
                top: -@5px;
                max-width: @460px;
                .one-line;
                &:last-child{
                  margin-bottom: 0;
                }
              }
              span{
                color: #999;
              }
            }
            >p{
              text-align: right;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .exchangeType{
                font-size: @26px;
                color: #61adfa;
                font-weight: 600;
                &.type_1{
                  color: #46c300;
                }
                &.type_2{
                  color: #ff6633;
                }
                &.type_3{
                  color: #333333;
                }
              }
              .goUse{
                display: block;
                color: #008cdc;
                font-size: @26px;
                position: relative;
                margin-bottom: @9px;
                right: -@6px;
                filter: grayscale(0);
                &.type_3{
                  font-size: @26px;
                }
                i{
                  font-size: @24px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
    .adrBtn{
      display: flex;
      justify-content: center;
      align-items: center;
      width: @300px;
      height: @80px;
      background: #FF6633;
      border-radius: 35px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: @30px;
      color: #FFFFFF;
      margin: 0 auto;
      margin-top:@60px;
    }
</style>