<template>
    <div class="homecontent_livevideo_bigBox">
        <div v-if="livevideoContentListtype">
            <div class="homecontent_livevideo_box">
                <div class="homecontent_livevideo_title">视频直播</div>
                <div @click="golivevideomore(item.serviceID)" class="homecontent_livevideo_contentbox"
                    v-for="(item,index) in livevideoContentList" :key="index">
                    <div>
                        <div v-if="!item.type" class="homecontent_livevideo_label" style="background:#00B277">即将开始</div>
                        <div v-if="item.type" class="homecontent_livevideo_label">直播中</div>
                        <img class="livevideo_content_img" :src="item.url" alt="">
                        <div class="livevideo_content_title">{{item.title}}</div>
                        <div v-if="!item.type" class="livevideo_countdown_box">
                            <div>距离开始:</div>
                            <div v-if="item.day > 0" class="livevideo_countdown_end"
                                style="color:#00B277;border:#00B277 1px solid">{{item.day}}天</div>
                            <div v-if="item.day > 0" style="color:#00B277"> : </div>
                            <div v-if="item.hours > 0" class="livevideo_countdown_end"
                                style="color:#00B277;border:#00B277 1px solid">{{item.hours}}时</div>
                            <div v-if="item.hours > 0" style="color:#00B277"> : </div>
                            <div v-if="item.minutes > 0" class="livevideo_countdown_end"
                                style="color:#00B277;border:#00B277 1px solid">{{item.minutes}}分</div>
                            <div v-if="item.minutes > 0" style="color:#00B277"> : </div>
                            <div class="livevideo_countdown_end" style="color:#00B277;border:#00B277 1px solid">
                                {{item.seconds}}秒</div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="homecontent_BIO_xian"></div>
        </div>
    </div>
</template>

<script>
import { postliveVideoAPI } from '../../api/home'
export default {
    name: 'livevideo',
    // 视频直播
    components: {

    },
    data() {
        return {
            livevideoContentList: [],
            seTtimeouts: '',
            livevideoContentListtype: false
        }
    },
    created() {
        this.postliveVideo()
    },
    methods: {
        postliveVideo() {
            //视频直播列表
            postliveVideoAPI()
                .then(res => {
                    let list = res.data.data || []
                    list.map((v, index) => {
                        if (index == 0) {
                            this.livevideoContentListtype = true
                        }
                        let newurl = JSON.parse(v.picture)
                        v.url = newurl[0].url //处理地址
                        if (v.state == 0) {//处理状态
                            v.type = false
                            // 先计算每条数据的倒计时差值
                            let starttimes = parseInt((new Date(v.startTime).getTime()) / 1000);
                            let newtimes = parseInt((new Date().getTime()) / 1000)
                            v.difftime = starttimes - newtimes
                        } else {
                            v.type = true
                        }
                    })
                    this.livevideoContentList = list
                    this.settimeoutfuntion()
                })
        },
        settimeoutfuntion() {
            // //视频未开始倒计时
            let endtime = 0
            this.livevideoContentList.map(v => {
                if (v.state == 0) {
                    if (v.difftime > 0) {
                        v.day = parseInt(v.difftime / (60 * 60 * 24))
                        v.hours = parseInt(v.difftime / (60 * 60) % 24)
                        v.minutes = parseInt(v.difftime / 60 % 60)
                        v.seconds = parseInt(v.difftime % 60)
                        v.difftime = v.difftime - 1
                        this.$forceUpdate()
                    } else {
                        endtime = 1
                    }
                }
            })
            if (this.seTtimeouts) clearTimeout(this.seTtimeouts)
            this.seTtimeouts = setTimeout(() => {
                if (endtime == 0) {
                    this.settimeoutfuntion()
                } else {
                    this.postliveVideo()
                }
            }, 1000);


        },
        golivevideomore(e) {
            this.$router.push({
                name: 'livevideomore',
                query: {
                    serviceID: e
                }
            })
        }
    },
    beforeDestroy() {
        clearTimeout(this.seTtimeouts)
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.homecontent_livevideo_bigBox {
    width: 100%;
}

.homecontent_BIO_xian {
    width: 100%;
    height: @10px;
    background: #F8F8F8;
    margin-top: @30px;
}

.homecontent_livevideo_box {
    width: @700px;
    margin-left: @25px;
}

.homecontent_livevideo_title {
    font-size: @34px;
    // width: @136px;
    height: @48px;
    font-weight: bold;
    margin-top: @30px;
}

.homecontent_livevideo_contentbox {
    width: 100%;
    position: relative;
    padding-bottom: @30px;
    border-bottom: 1px solid #F8F8F8;
}

.homecontent_livevideo_contentbox:last-child {
    border-bottom: 1px solid #FFF;

}

.homecontent_livevideo_label {
    position: absolute;
    top: @40px;
    left: @20px;
    width: @110px;
    height: @44px;
    font-size: @24px;
    background: #EF882C;
    border-radius: @6px;
    color: #FFFFFF;
    text-align: center;
    line-height: @44px;
}

.livevideo_content_img {
    width: @700px;
    height: @290px;
    border-radius: @20px;
    margin-top: @30px;
    object-fit: cover;

}

.livevideo_content_title {
    margin-top: @10px;
    font-size: @34px;

}

.livevideo_countdown_box {
    width: 100%;
    height: @40px;
    display: flex;
    align-items: center;
    font-size: @24px;
    margin-top: @20px;
}

.livevideo_countdown_end {
    width: @70px;
    height: @36px;
    color: #EF882C;
    border: #EF882C 1px solid;
    border-radius: @2px;
    text-align: center;
    margin-left: @20px;
    margin-right: @20px;
    line-height: @36px;
}
</style>