import axios from 'axios'
import Vue from 'vue'
import { Toast } from 'vant';
import { Dialog } from 'vant';
Vue.use(Toast);
Vue.use(Dialog);
import VueCookie from 'vue-cookie'
import {
  getAuthUrlAPI,
  wxLogin,
} from '../api/log'
import router from '@/router/index'
import {toast} from "@/mixins/popup";
let env = process.env.NODE_ENV
// import qs from 'qs'
// import { baseURL } from './api'
// 自定义axios配置
const service = axios.create({
  // baseURL: 'http://192.168.110.18:9280',
  // baseURL: 'http://test.h5.bio.ichuangye.cn', //测试
  // baseURL: 'http://192.168.110.180:9527', //测试
  baseURL: 'https://h5.bio.ichuangye.cn',//正式
  timeout: 60000,
  // responseType: "json",
  withCredentials: false,
  // withCredentials: true,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // },
  // paramsSerializer: data => qs.stringify(data),
})
// service.defaults.transformRequest = data => qs.stringify(data)

/**
 * 请求拦截
 */
service.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
service.interceptors.response.use(
  response => {
    // console.log(response.data)
    if (response.data.code === 3000) {
      // 未登录
      getWechatCode()
      function getWechatCode() {
        let url = window.location.href
        let code = getUrlCode().code; //是否存在code
        let firstLogins = localStorage.getItem('firstLogins')
        if (code == null || code === '') { // 如果没有code，则去请求
          console.log('未查询到code')
          if(env !== 'development') {
            let data = {
              serviceCode: "bio",
              url: url
            }
            getAuthUrlAPI(data)
              .then(res => {
                localStorage.setItem('firstLogins', 2)
                location.replace(res.data.data) // 重定向
              })
          }
        } else {
          // 你自己的业务逻辑
          if (Number(firstLogins) === 1) {
            let data = {
              serviceCode: "bio",
              url: url
            }
            console.log(111);
            getAuthUrlAPI(data)
              .then(res => {
                localStorage.setItem('firstLogins', 2)
                location.replace(res.data.data) // 重定向
              })
          } else {
            // 微信登录
            wxLogin(code, "bio")
              .then(res => {
                localStorage.setItem('firstLogins', 1)
                VueCookie.set('JSESSIONID', res.data.data.serviceID)
                VueCookie.set('serviceCode', "bio")
                location.reload()
              })
          }
        }
      }
      function getUrlCode() {
        // 截取url中的code方法
        var urls = location.search;
        var theRequest = new Object();
        if (urls.indexOf("?") != -1) {
          var str = urls.substr(1);
          var strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
          }
        }
        return theRequest;
      }
    } else if(response.data.code === 701) {
      // 未注册
      Dialog.alert({
        title: '提示',
        message: '您还未注册，请先注册！',
      }).then(() => {
        // on close
        router.push('/regsiter')
      });
      return Promise.reject(response)
    } else if (response.data.code == 704) {
      Toast(response.data.msg)
      return Promise.reject(response)
    } else if (response.data.code == 500) {
      Toast(response.data.msg)
      return Promise.reject(response)
    } else if (response.data.code !== 200) {
      return Promise.reject(response)
    }
    return Promise.resolve(response)
  }, error => {
    return Promise.reject(error)
  }
)
export default service
