<template>
    <div class="bigbox">
      <van-skeleton title :row="5" :loading="loading">
        <div class="head_bigbox">
            <input @keyup.enter="clickSearch" @focus="focusinput" v-model="inputSearchValue" class="input_box"
                placeholder="输入标题搜索" type="search">
            <div>
                <SvgIcon class="input_svg" iconClass="icon-sousuo-10"></SvgIcon>
            </div>
            <div v-if="inputSearchValue != ''" @click="clearSearch" class="input_X">
                <svg t="1665641559757" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="22455" width="16" height="16">
                    <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#AAAAAA" p-id="22456">
                    </path>
                    <path
                        d="M583.68 512l136.533333-136.533333c20.48-20.48 20.48-51.2 0-71.68s-51.2-20.48-71.68 0l-136.533333 136.533333-136.533333-136.533333c-20.48-20.48-51.2-20.48-71.68 0s-20.48 51.2 0 71.68l136.533333 136.533333-136.533333 136.533333c-20.48 20.48-20.48 51.2 0 71.68s51.2 20.48 71.68 0l136.533333-136.533333 136.533333 136.533333c20.48 20.48 51.2 20.48 71.68 0s20.48-51.2 0-71.68l-136.533333-136.533333z"
                        fill="#FFFFFF" p-id="22457"></path>
                </svg>
            </div>
            <div v-if="searchtype" @click="clickSearch" class="input_search">搜索</div>
        </div>
        <div class="xian"></div>
        <div class="content_bigbox">
          <m-scroll ref="mscroll" v-model="pages" :getData="postrecruitmentlistAPI" emptyText="暂无数据">
            <div class="content_box" v-for="(item,index) in contentList" :key="index" @click="toDetail(item.serviceID, index)">
              <div class="content_textbox">
                <div class="content_textleft">
                  {{item.jobName}}
                </div>
                <div class="content_textright">
                  {{item.negotiable === 1 ? "面议" : item.money}}
                </div>
              </div>
              <div class="content_textbottom">
                <SvgIcon class="content_svg" iconClass="icon-suoshugongsiquancheng"></SvgIcon>
                <span class="content_texttype">{{item.companyName}}</span>
              </div>
            </div>
          </m-scroll>
        </div>
      </van-skeleton>
    </div>
</template>

<script>
import { postrecruitmentlist } from '../../api/personnel'
import {postCommonAddClick} from "@/api/home";
export default {
    name: 'serviceMore',
    // 人才招聘详情列表
    data() {
        return {
          loading: true,
          contentList: [],//列表
          inputSearchValue: '',//搜索框
          searchtype: false,
          pages: {
            page: 1,
            pageSize: 10,
            kv: {
              jobName: ''
            }
          }
        }
    },
    mounted() {
        this.postrecruitmentlistAPI()
    },
    methods: {
        focusinput() {
            //获取焦点
            this.searchtype = true
        },
        clearSearch() {
            // 取消
          this.inputSearchValue = ''
          this.searchtype = false
          this.pages.page = 1
          this.postrecruitmentlistAPI()
        },
        clickSearch() {
          this.pages.page = 1
          this.postrecruitmentlistAPI()
        },
        postrecruitmentlistAPI() {
          this.pages.kv.jobName = this.inputSearchValue
          if (this.pages.page === 1) this.contentList = []
            postrecruitmentlist(this.pages)
              .then(res => {
                this.loading = false
                let list = res.data.data.list
                list.map(v => {
                    if (v.negotiable == 0) {
                        v.money = String(v.minSalary*0.001) + '-' + String(v.maxSalary*0.001) + "K"
                    }
                })
                this.contentList = [...this.contentList, ...list]
                this.$nextTick(_ => {
                  this.$refs.mscroll.endSuccess(res.data.data)
                })
              })
        },
      toDetail(id, index) {
        this.addViews(this.contentList[index].recruitmentID, 'recruitment')
        this.$router.push(`/recruitmore?id=${id}`)
      },
      //  增加阅读量
      addViews(id, key) {
        postCommonAddClick(key, id)
      }
    }

}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";
@import "../../assets/css/minix/minix";
.bigbox {
  background-color: #F8F8F8;
  height: 100vh;
}
.head_bigbox {
  padding: @30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #fff;


}

.input_box {
    width: 100%;
    height: @70px;
    background: #F6F6F6;
    border: 1px solid #FFF;
    font-size: @30px;
    border-radius: @40px;
    /* border: 0 none; */
    /* outline: none; */
    padding-left: @60px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

.input_svg {
    width: @30px;
    height: @30px;
    position: absolute;
    left: @50px;
    top: @50px;
}

.input_X {
    width: @20px;
    height: @30px;
    position: absolute;
    right: @145px;
    top: @50px;

}


.input_search {
    width: @70px;
    margin-left: @10px;
    /* display: flex;
    align-items: center; */
    text-align: center;
    line-height: @70px;
    font-size: @28px;
    color: #00B277;
}

.xian {
    width: 100%;
    height: @10px;
    background: #F8F8F8;

}

.content_bigbox {
    width: 100%;

}

.content_title {
    margin-top: @30px;
    margin-left: @30px;
    font-size: @34px;
    font-weight: bold;
}

.content_box {
    width: @690px;
    margin-top: @20px;
    margin-left: @30px;
    background: #FFFFFF;
    padding: @30px;
    border-radius: @10px;
}

.content_textbox {
    width: 100%;
    font-size: @34px;
    line-height: @48px;
    display: flex;
    justify-content: space-between;
  align-items: center;

}

.content_textleft {
    max-width: @420px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

.content_textright {
    color: #EF882C;
}

.content_textbottom {
  width: 100%;
  margin-top: @10px;
  font-size: @28px;
  line-height: @40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_svg {
    width: @30px;
    height: @30px;
}

.content_texttype {
    color: #666666;
    margin-left: @10px;
    line-height: @40px;
  .line1
}

.content_bottombox {
    width: 100%;
    height: @150px;
    color: #2878FF;
    font-size: @28px;
    text-align: center;
    margin-top: @30px;
}
</style>
