<template>
  <div class="mscroll">
    <!-- 数据 -->
    <slot></slot>
    <!-- 加载中 -->
    <div v-if="pages.hasNextPage || pages.page === 1" class="loading-wrap">
      <div
        class="loading"
        :class="{ absolute: pages.page === 1 }"
      >
        <spinner type="ios-small"></spinner>
        <span>加载中…</span>
      </div>
      <!-- <span v-if="pages.total > 10 && !pages.hasNextPage && noMore" class="noMore">没有更多数据啦…</span> -->
    </div>
    <!-- 空布局 -->
    <transition name="fade">
      <div v-show="showEmpty" class="noData">
        <img src="./image/nodata.png" alt="" />
        <p>{{ emptyText }}</p>
        <button v-if="emptyBtn" @click="$emit('emptyFn')">
          {{ emptyBtn }}
        </button>
      </div>
    </transition>
    <!-- 回到顶部 -->
<!--    <transition name="toTop" v-if="showToTop">-->
<!--      &lt;!&ndash; <iconfont href='icon-toTop' v-show="showTop" :class="{running}" @click.native="toTop(0)" o-fb/> &ndash;&gt;-->
<!--      <div class="toTop" @click="toTop(0)" o-fb>-->
<!--        <iconfont href="icon-refresh" />-->
<!--      </div>-->
<!--      &lt;!&ndash; <img src="./image/火箭.png" @click="toTop(0)" class="toTop" alt=""> &ndash;&gt;-->
<!--    </transition>-->
  </div>
</template>

<script>
import { Spinner } from 'vux'
export default {
  props: {
    value: Object, // 页码
    getData: Function, // 获取数据的方法
    emptyText: String, // 空布局文字提示
    emptyBtn: String, // 空布局按钮文字
    noMore: {
      // 是否显示无更多数据文字
      type: Boolean,
      default () {
        return true
      }
    },
    showToTop: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loadingLock: true, // 加载锁，true表示不加载
      running: false,
      timer: '', // 上拉刷新延迟计时器
      top: 0, // 滚动条的位置
      showEmpty: false, // 用于控制空布局显示
      showTop: false, // 控制显回到顶部按钮隐藏
      dom: '', // 父容器dom
      success: true // 是否加载完成
    }
  },
  components: { Spinner },
  mounted () {
    /**
     * addEventListener
     * event,
     * function,
     * useCapture: 可选，布尔值，指定事件是否在捕获或冒泡阶段执行
     */
    window.addEventListener('scroll', () => {
      this.scroll()
    }, true)
  },
  computed: {
    pages () {
      if (this.value.hasNextPage === undefined) {
        //eslint-disable-next-line
        this.value.hasNextPage = false
      }
      return this.value
    }
  },
  methods: {
    scroll () {
      // 解决兼容性有的手机识别document.documentElement，有的手机识别document.body
      if (document.body.scrollTop === 0) {
        this.dom = document.documentElement
      } else {
        this.dom = document.body
      }
      // 变量scrollTop是滚动条滚动时，距离顶部的距离
      this.scrollTop = this.dom.scrollTop
      this.$emit('scroll', this.scrollTop)
      // 变量windowHeight是可视区的高度
      let windowHeight = this.dom.clientHeight
      // 变量scrollHeight是滚动条的总高度
      let scrollHeight = this.dom.scrollHeight
      // 可视区的高度 + 滚动条的总高度
      let scrollWindow = this.scrollTop + windowHeight
      //  滚动条到底部的条件
      if (
        this.scrollTop &&
        scrollWindow > scrollHeight - 5 &&
        scrollWindow < scrollHeight + 5
      ) {
        if (this.loadingLock || !this.pages.hasNextPage) return
        this.loadingLock = true
        this.timer = setTimeout(() => {
          this.getData()
          clearTimeout(this.timer)
        }, 100)
      }
    },
    refresh () {
      if (!this.success) return
      this.success = false // 防止快速点击数组push多条数据
      this.showEmpty = false // 刷新隐藏空布局
      this.$emit('input', {
        page: 1,
        pageSize: this.pages.pageSize,
        hasNextPage: false
      })
      this.getData()
    },
    // 页面返回，回到原位置
    getTop () {
      // document.querySelector(`.${this.parent}`).scrollTop = this.scrollTop
    },
    // 离开页面，存离开时的位置
    setTop () {
      // this.scrollTop = this.dom.scrollTop
    },
    // 回到顶部
    toTop (position, noRefresh) {
      // if (this.running) {
      //   this.running = false
      //   document.querySelector('.toTop').style.webkitAnimationPlayState = 'paused'
      // } else {
      //   this.running = true
      //   document.querySelector('.toTop').style.webkitAnimationPlayState = 'running'
      // }
      // 当前滚动高度
      let that = this
      let scrollTop = this.dom.scrollTop
      if (!scrollTop) {
        that.refresh()
        return
      }
      function step () {
        let distance = position - scrollTop
        scrollTop = scrollTop + distance / 8
        if (Math.abs(distance) < 1) {
          window.scrollTo(0, position)
          if (!noRefresh) that.refresh()
        } else {
          window.scrollTo(0, scrollTop)
          requestAnimationFrame(step)
        }
      }
      step()
    },
    // 加载成功
    endSuccess (data) {
      this.loadingLock = false
      this.success = true // 防止快速点击数组push多条数据
      this.showEmpty = !data.total // 无数据展示空布局
      this.$emit('input', {
        ...this.pages,
        hasNextPage: data.hasNextPage,
        total: data.total,
        page: this.pages.page + 1
      })
    },
    // 加载失败
    endError () {
      this.pages.page = ''
      this.showEmpty = true
    },
    // 发起滚动监听
    addScrollListener () {
      console.log('监听成功')
      window.addEventListener('scroll', this.scroll)
    },
    // 清除滚动监听
    removeScrollListener () {
      console.log('清除成功')
      clearTimeout(this.timer)
      window.removeEventListener('scroll', this.scroll)
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    window.removeEventListener('scroll', this.scroll)
  }
}
</script>

<style lang='less' scoped>
@import "../../assets/css/pixel/pixel";
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mscroll {
  background: #f8f8f8;
  position: relative;
  scroll-behavior: smooth;
  height: 100%;
  overflow-y: scroll;

  // 加载中
  .loading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    span {
      font-size: @24px;
      color: gray;
      padding: @32px 0;
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: @18px;
      &.absolute {
        position: absolute;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        margin-left: @10px;
        padding: 0;
      }
    }
  }
  // 无数据
  .noData {
    text-align: center;
    color: gray;
    font-size: @32px;
    line-height: @32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: @240px;
    img {
      width: @500px;
      height: @400px;
      margin-bottom: @28px;
    }
    p {
      font-size: @28px;
    }
    button {
      margin: @28px auto;
      padding: @20px @50px;
      border-radius: @100px;
      background-color: #ff6633;
      color: #fff;
      font-size: @28px;
    }
  }
  // 回到顶部
  .toTop-enter-active,
  .toTop-leave-active {
    transition: all 0.72s;
  }
  .toTop-enter,
  .toTop-leave-to {
    opacity: 0;
    transform: translateY(100px);
  }
}
.toTop {
  display: inline-block;
  color: #fe7547;
  position: fixed;
  width: @72px;
  height: @72px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  z-index: 12;
  right: @24px;
  bottom: @20px;
  opacity: 0.85;
  .icon-svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &.running {
    animation: rotate 0.6s infinite linear;
  }
}
</style>
