<template>
  <div class="home_biology_bigBox">
    <div class="home_biology_box">
      <!-- 顶部 -->
      <div class="home_biology_titleBox">
        <SvgIcon class="home_biology_svg" iconClass="icon-shengwukepu-08"></SvgIcon>
        <!-- <div class="home_biology_title">聚集生物城热点</div> -->
        <div class="home_biology_more" @click="gomore">查看更多</div>
      </div>
      <!-- 内容 -->
      <div @click="golink(index)" v-for="(item,index) in biologyList" :key="index" class="home_biology_contentBox">
        <img class="home_biology_img" src="../../assets/home/生物标题.png" alt="">
        <div class="home_biology_text">{{item.title}}</div>
      </div>

    </div>
  </div>
</template>
  
<script>
import { postbiologicalScienceAPI,postCommonAddClick } from '../../api/home'
export default {
  name: 'biology',
  // 生物科普 
  data() {
    return {
      biologyList: [
      ]
    }
  },
  methods: {
    gomore() {
      this.$router.push('/biologymore')
    },
    postbiologicalScience() {
      // 生物科普 首页
      postbiologicalScienceAPI()
        .then(res => {
          let list = res.data.data || []
          list.map(v => {
            if (v.video) v.videos = JSON.parse(v.video) //videos.url
          })
          this.biologyList = list
        })
    },
    golink(index) {
      //计算浏览量
      postCommonAddClick('biologicalScience', this.biologyList[index].biologicalScienceID)
            .then(res => {
            })
      // 去外链
      if (this.biologyList[index].source == 2) {
        this.$router.push({
          name: 'article',
          query: {
            serviceID: this.biologyList[index].serviceID,
            from:"生物科普"
          }
        })
        return
      }

      if (this.biologyList[index].type == 1) {
        this.$emit("videoUrl", this.biologyList[index].videos.url)
      } else {
        if (this.biologyList[index].link) window.location.href = this.biologyList[index].link
      }

    }
  },
  created() {
    this.postbiologicalScience()
  }
}
</script>
  
<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.home_biology_bigBox {
  width: 100%;
}

.home_biology_box {
  width: @700px;
  height: @268px;
  margin-left: @25px;
  margin-top: @30px;
  background: -webkit-linear-gradient(left, rgba(0, 178, 119, 0.08), rgba(0, 178, 119, 0.05));
  border-radius: @20px;
}

.home_biology_titleBox {
  width: 100%;
  height: @66px;
  position: relative;
  margin-bottom: @28px;
}

.home_biology_svg {
  width: @200px;
  height: @32px;
  position: absolute;
  bottom: 0;
}

.home_biology_title {
  font-size: @24px;
  position: absolute;
  bottom: 0;
  left: @200px;
  color: #777777;
}

.home_biology_more {
  width: @130px;
  height: @44px;
  border-radius: @22px;
  background: rgba(0, 178, 119, 0.07);
  position: absolute;
  top: @20px;
  right: @20px;
  font-size: @24px;
  color: #00B277;
  text-align: center;
  line-height: @44px;
}

.home_biology_contentBox {
  margin-top: @14px;
  width: 100%;
  height: @40px;
  display: flex;
  align-items: center;
  font-size: @28px;
  color: #333333;
}

.home_biology_img {
  width: @26px;
  height: @30px;
  margin-left: @30px;
  object-fit: cover;
}

.home_biology_text {
  width: 85%;
  height: 100%;
  margin-left: @10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: @30px;
}
</style>