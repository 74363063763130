<template>
  <div class="activity">
    <div class="notice">{{ dateFilter(info.drawDateTime, 'MM月DD日 HH:mm') }}开奖</div>
    <div @click="showRule=true" class="rule"></div>
    <div class="title">{{ info.activityName }}</div>
    <div class="card-box">
      <div class="head" v-if="info.wheelNum===1">{{ info.complete1Num }}人已集齐</div>
      <div class="head" v-else>{{ info.complete1Num }}人已集齐1套，{{ info.complete2Num }}人已集齐2套</div>
      <ul v-if="timeDraw.state!=='end'" class="content-body"
          :style="{'backgroundImage':`url(${info.imgUrl})`}"
          :class="'full_'+info.chipNum">
        <li v-show="!completeAll" class="cover"></li>
        <li :class="{'completeAll': completeAll}" v-for="el in info.chipNum" class="card-item">
          <span :class="{'empty':myCard[el]===0}" class="badge">{{ myCard[el] }}</span>
          <span class="text">{{ el }}</span>
        </li>
      </ul>
      <ul v-if="timeDraw.state==='end'&&!info.myCompoundNum" class="content-body"
          :style="{'backgroundImage':`url(${info.imgUrl})`}"
          :class="'full_'+info.chipNum">
        <li v-show="!completeAll" class="cover"></li>
        <li :class="{'completeAll': completeAll}" v-for="el in info.chipNum" class="card-item">
          <span class="badge">{{ myCard[el] }}</span>
          <span class="text">{{ el }}</span>
        </li>
      </ul>
      <div :options="pointBannerNoAutoPlay" class="result-swiper" v-if="timeDraw.state==='end'&&info.myCompoundNum">
        <div class="gotMoney">
          <h1>{{ iGotMoney.redMoneyNum }}<span>元</span></h1>
          <p>所获得红包将直接到账微信零钱</p>
          <img class="img" src="../../assets/images/benefitServe/puzzle/红包.png" alt="">
        </div>
<!--        <swiper-slide class="acpic">-->
<!--          <img v-lazy="info.imgUrl" alt="">-->
<!--        </swiper-slide>-->
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
      <p class="myCardInfo" v-if="info.myCompoundNum&&info.wheelNum===2&&timeDraw.state!=='end'">
        {{ info.myCompoundNum < info.wheelNum ? '您已经合成1套,还可继续合成' : '您已合成全部卡片' }}</p>
    </div>
    <div class="btn">
      <div  :class="{'disable':disabled}" v-if="showAnswer&&!completeAll" @click="startAnswer" class="begin">
        <div class="tip">还剩{{ answerRule.surplusAnswerNumToday }}次 {{ data.needScore }}积分/次</div>
        <template v-if="answerRule.surplusAnswerNumToday"> {{ btnText }} </template>
        <template v-if="!answerRule.surplusAnswerNumToday"> 开始答题 </template>
      </div>

      <div v-if="completeAll&&timeDraw.state!=='end'" class="waiting">
        等待开奖
      </div>
    </div>
    <m-dialog v-model="showPay">
      <div class="pay-dialog">
        <h1 class="inner-title">确认支付积分</h1>
        <p>{{ data.needScore }}</p>
        <div class="inner-btn">
          <button @click="showPay = false">取消</button>
          <button @click="payScore">确定</button>
        </div>
      </div>
    </m-dialog>
    <m-dialog v-model="showRule" show-close showMask>
      <div class="rule-dialog">
        <div class="title-img"></div>
        <div class="inner-title">
          <img src="../../assets/images/benefitServe/puzzle/装饰2.png" alt="">
          <span>规则说明</span>
          <img src="../../assets/images/benefitServe/puzzle/装饰1.png" alt="">
        </div>
        <div v-if="info.qoManage" class="rule-info">
          <p class="vux-1px-b">活动时间<span>{{ info.startTime }}至{{ info.endTime }}</span></p>
          <p class="vux-1px-b">答题时间<span>{{ info.qoStartTime }}至{{ info.qoEndTime }}</span></p>
          <p class="vux-1px-b">答题题数<span>{{ info.qoManage.answerNum }}题/次</span></p>
          <p class="vux-1px-b">每题用时<span>{{ info.qoManage.answerTime }}秒/题</span></p>
          <p class="vux-1px-b">参与次数<span>{{ info.qoManage.participateNum }}/次/天/人</span></p>
          <p class="vux-1px-b">参与所需积分<span>{{ info.qoManage.needScore }}积分/次</span></p>
        </div>
        <div class="inner-title">
          <img src="../../assets/images/benefitServe/puzzle/装饰2.png" alt="">
          <span>奖励说明</span>
          <img src="../../assets/images/benefitServe/puzzle/装饰1.png" alt="">
        </div>
        <div class="reward-info">
          <p>1、答对{{ info.answerTrueNum }}题，即可获得拼图碎片1张。</p>
          <p>2、每人最多可合成{{ info.wheelNum }}套拼图。{{ info.wheelNum === 2 ? '合成套数越多，奖励越大' : '' }}</p>
          <p>3、集成可瓜分{{ info.redMoneyNum }}元现金红包。 </p>
        </div>
      </div>
    </m-dialog>
    <m-dialog v-model="showCard">
      <div class="result-dialog">
        <h1 class="inner-title">合成成功</h1>
        <p>可继续答题集碎片，每人最多合成{{ info.wheelNum }}套</p>
        <ul class="card-box_1" :class="'full_'+info.chipNum">
          <li v-for="el in info.chipNum" :style="{'backgroundImage':`url(${info.imgUrl})`}"></li>
        </ul>
        <div class="btn inner-btn">
          <button class="back" @click="showCard=false">确定</button>
        </div>
      </div>
    </m-dialog>
  </div>
</template>

<script>
import MDialog from '@/components/dialog/MDialog/MDialog'
import {
  getQuizGameRule,
  isAnswerApi,
  startAnswerApi
} from '@/api/activity/quizGame/index'
import {computedChipApi, getActivityApi, winListChipApi} from '@/api/activity/puzzle/index'
// import {toast} from '@/mixins/popup'
 import {Toast} from 'vant'
import {computed, beginToEnd, dateFilter} from '@/mixins/filters'
// import {mapGetters} from 'vuex'
import {pointBannerNoAutoPlay} from '@/mixins/data/defData'

export default {
  name: 'puzzle-activity',
  components: {MDialog},
  computed: {
    btnText() {
      // status 0 未开始，1 进行中，2 已结束
      if (this.status===0) {
        return '敬请期待'
      } else if (this.status === 1) {
        return '答题进行中'
      }else if (this.status === 2) {
        return '答题已结束'
      } else if (this.status === 3) {
        return '活动未开始'
      } else if (this.status === 4) {
        return '活动已结束'
      } else {
        return this.btnTextType[this.answerType]
      }
    },
    disabled() {
      if (!this.status) {
        return true
      } else if (this.status === 2) {
        return true
      } else if (this.status === 3) {
        return true
      } else if (this.status === 4) {
        return true
      } else if (this.answerType === 2) {
        return true
      } else {
        return false
      }
    }
    // ...mapGetters(['loginStatus'])
  },
  data() {
    return {
      pointBannerNoAutoPlay,
      showRule: false,
      completeAll: false,
      showAnswer: true,
      showPay: false,
      showCard: false,
      answerType: '',
      timer: null,
      time: {},
      btnTextType: ['答题得碎片', '继续答题', '无答题次数'],
      status: '',
      data: {},
      info: {},
      qoId: '',
      timerActivity: null,
      timeActivity: {},
      answerRule: {},
      myCard: {},
      isComplete: false,
      timerDraw: null,
      timeDraw: {},
      iGotMoney: {},
      grid: {
        4: [2, 2],
        6: [2, 3],
        8: [4, 2],
        9: [3, 3]
      },
      image: null,
      picTimer: null
    }
  },
  methods: {
    computed,
    beginToEnd,
    dateFilter,
    flipCard(row, col) {
      this.picTimer = setInterval(() => {
        let width = this.image.width
        if (width) {
          let box = document.getElementsByClassName('card-box_1')[0]
          for (let y = 0; y < col; y++) {
            for (let x = 0; x < row; x++) {
              let canvas = document.createElement('canvas')
              canvas.width = this.image.width / row
              canvas.height = this.image.height / col
              let context = canvas.getContext('2d')
              context.drawImage(this.image, x * (this.image.naturalWidth / row), y * (this.image.naturalHeight / col), canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
              const bg = `background:url(${canvas.toDataURL()}) no-repeat center center`
              let dom = document.createElement('li')
              dom.setAttribute('class', 'son')
              dom.setAttribute('style', bg)
              box.appendChild(dom)
            }
          }
          // toast('success', '您已合成一套，还可继续答题合成获得更多奖励')
          Toast('您已合成一套，还可继续答题合成获得更多奖励');
          clearInterval(this.picTimer)
        }
      }, 100)
    },
    payScore() {
      startAnswerApi({serviceID: this.qoId}).then(data => {
        this.showPay = false
        console.log(this.data)
        console.log(this.info)
        this.$router.push(`/puzzle/answer/${this.qoId}?total=${this.data.answerNum}&second=${this.data.answerTime}&ruleID=${this.info.chipRuleID}&rightScore=${this.info.answerTrueNum}&myCompoundNum=${this.info.myCompoundNum}`)
      })
    },
    completeCard() {
      computedChipApi(this.info.chipRuleID).then(res => {
        this.info.myCompoundNum++
        if (this.info.myCompoundNum === this.info.wheelNum) {
          this.completeAll = true
        } else {
          for (let i in this.myCard) {
            this.myCard[i]--
          }
          this.showCard = true
        }
      })
    },
    // 获取本期规则
    getRule() {
      getQuizGameRule(this.qoId).then((data) => {
        this.data = data.data.data || {}
      })
    },
    // 答题状态
    isAnswer() {
      // 0 今天从未答题
      // 1 今天已答题，但未全部答完
      // 2 今天已答题，并全部答完
      isAnswerApi(this.qoId).then((data) => {
        this.answerRule = data.data.data
        this.answerType = data.data.data.answerStatus
      })
    },
    getMyReward() {
      winListChipApi({
        page: 1,
        pageSize: 1,
        kv: {
          chipRuleID: this.info.chipRuleID
          // usersID: this.loginStatus.usersID
        }
      }).then(res => {
        if (res.data.data.list.length) {
          this.iGotMoney = res.data.data.list[0]
        }
        clearInterval(this.timerDraw)
      })
    },
    // 点击按钮
    startAnswer() {
      // 未开始
      if (this.status===0) {
        // toast('warn', '答题活动未开始')
        Toast('答题活动未开始')
        return
      }
      if (this.status===1) {
        // toast('warn', '答题活动进行中')
        Toast('答题活动进行中')
        return
      }
      // 已结束
      if (this.status === 2) {
        // toast('warn', '答题活动已结束')
        Toast('答题活动已结束')
        return
      }
      if (this.status === 3) {
        // toast('warn', '活动未开始')
        Toast('活动未开始')
        return
      }
      if (this.status === 4) {
        // toast('warn', '活动已结束')
        Toast('活动已结束')
        return
      }
      // 已答
      if (this.answerType === 2) {
        // toast('warn', '今天已经答过啦，明天再来吧')
        Toast('今天已经答过啦，明天再来吧')
        return
      }
      // 按钮为开始答题，未支付积分
      if (!this.answerType) {
        this.showPay = true
        return
      }
      // 已支付积分
      this.$router.push(`/puzzle/answer/${this.qoId}?total=${this.data.answerNum}&second=${this.data.answerTime}&ruleID=${this.info.chipRuleID}&rightScore=${this.info.answerTrueNum}&myCompoundNum=${this.info.myCompoundNum}`)
    },
    // 倒计时
    countDown(start, end) {
      this.timeCount(start, end)
      this.timer = setInterval(() => {
        this.timeCount(start, end)
      }, 1000)
    },
    timeCount(start, end) {
      let time = end
      let now = new Date().getTime()
      if (now < start) {
        this.status = 0 // 未开始
        time = start
      } else if (now >= start && now <= end) {
        time = end
        this.status = 1 // 进行中
      } else if (now > end) {
        this.status = 2 // 已结束
        this.time = {d: '00', h: '00', m: '00', s: '00'}
        clearInterval(this.timer)
        return
      }
      this.time = this.computed(time - now, true)
    },
    getActivity() {
      this.showAnswer = true
      getActivityApi(this.id).then(res => {
        res = res.data.data
        this.qoId = res.qoManage.serviceID
        this.info = res
        res.startDateTime = this.dateFilter(res.startDateTime, 'YYYY/MM/DD HH:mm:ss')
        res.endDateTime = this.dateFilter(res.endDateTime, 'YYYY/MM/DD HH:mm:ss')
        res.drawDateTime = this.dateFilter(res.drawDateTime, 'YYYY/MM/DD HH:mm:ss')
        this.info.startTime = this.dateFilter(new Date(res.startDateTime).getTime(), 'MM.DD HH:mm')
        this.info.qoStartTime = this.dateFilter(res.qoManage.beginTime, 'MM.DD HH:mm')
        this.info.endTime = this.dateFilter(new Date(res.endDateTime).getTime(), 'MM.DD HH:mm')
        this.info.qoEndTime = this.dateFilter(res.qoManage.endTime, 'MM.DD HH:mm')
        this.info.myCompoundNum === this.info.wheelNum ? this.completeAll = true : this.completeAll = false
        let start = new Date(res.startDateTime).getTime()
        let quizEndTime = new Date(res.qoManage.endTime).getTime()
        let quizStartTime = new Date(res.qoManage.beginTime).getTime()
        let end = new Date(res.endDateTime).getTime()
        let drawDateTime = new Date(res.drawDateTime).getTime()
        sessionStorage.setItem('chipRule', JSON.stringify(res))
        let myCard = {}
        if (res.myCollectChipList) {
          for (let i = 1; i <= res.chipNum; i++) {
            this.myCard[i] = 0
            myCard[i] = 0
          }
          let num = 0
          res.myCollectChipList.forEach(el => {
            this.myCard[el.chipNo] = el.chipNum
            myCard[el.chipNo] = el.chipNum
            if (res.myCompoundNum) {
              this.myCard[el.chipNo] = el.chipNum - res.myCompoundNum
            }
          })
          for (let i in this.myCard) {
            if (this.myCard[i] >= 1) {
              num++
            }
          }
          if (num >= res.chipNum) {
            if (res.myCompoundNum !== res.wheelNum) {
              this.completeCard()
            } else {
              this.completeAll = true
            }
          }
          sessionStorage.setItem('myChipCard', JSON.stringify(myCard))
        } else {
          sessionStorage.setItem('myChipCard', '[]')
        }
        this.timeActivity = this.beginToEnd(start, end)
        this.timeDraw = this.beginToEnd(start, drawDateTime)
        this.timerDraw = setInterval(() => {
          this.timeDraw = this.beginToEnd(start,drawDateTime)
          if (this.timeDraw.state === 'end') {
            if (res.myCompoundNum) {
              this.showAnswer = false
              this.getMyReward()
            }
            clearInterval(this.timerDraw)
            clearInterval(this.timer)
          }
        }, 1000)
        this.timerActivity = setInterval(() => {
          this.timeActivity = this.beginToEnd(quizStartTime, quizEndTime)
          if (this.timeActivity.state === 'noStart') {
            this.status = 3
            clearInterval(this.timer)
            clearInterval(this.timerDraw)
          }
          if (this.timeActivity.state === 'underway') {
            this.timeCount(res.qoManage.beginTime, res.qoManage.endTime)
          }
          if (this.timeActivity.state === 'end') {
            this.status = 4
            this.showAnswer = false
            clearInterval(this.timer)
            clearInterval(this.timerActivity)
            Toast('活动已结束')
          }
        }, 1)
        this.getRule()
        this.isAnswer()
      })
    }
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.timerActivity)
    clearInterval(this.timerDraw)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer)
    clearInterval(this.timerActivity)
    clearInterval(this.timerDraw)
    next()
  },
  activated () {
    this.getActivity()
  //   this.id = this.$route.params.id
},
  created () {
    this.id = this.$route.params.id
    // this.id = "73f93cd69feb431dbb72df55db83570a"
    this.getActivity()
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.activity {
  min-height: 100vh;
  background: url("../../assets/images/benefitServe/puzzle/bg.png") no-repeat center top;
  background-size: 100% auto;
  padding-top: @50px;
  padding-bottom: @50px;

  .notice {
    margin: 0 @135px 0;
    background: linear-gradient(90deg, rgba(0, 255, 186, 0), #0089FF, rgba(0, 167, 255, 0));
    border-radius: 4px;
    text-align: center;
    line-height: @42px;
    font-size: @26px;
    color: #FFFFFF;
  }

  .rule {
    position: fixed;
    top: @45px;
    right: 0;
    width: @120px;
    height: @60px;
    background: url("../../assets/images/benefitServe/puzzle/规则.png") no-repeat center center/cover;
  }

  .title {
    padding: 0 @96px;
    margin-top: @160px;
    font-size: @32px;
    font-weight: 800;
    color: #000000;
    line-height: @40px;
    text-align: center;
  }

  .card-box {
    margin: @60px auto;
    width: @660px;
    background: #FFFFFF;
    border-radius: @20px;
    padding: @20px 0 @30px;

    .head {
      font-size: @28px;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }

    .content-body {
      width: @600px;
      height: @600px;
      border-radius: @16px;
      display: grid;
      margin: @20px auto 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      grid-gap: 1px;
      position: relative;

      .cover {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, .5);
      }

      &.full_4 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      &.full_6 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      &.full_8 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      &.full_9 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }

      .card-item {
        background: rgba(19, 59, 99, 0.9);
        //background: #133963;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;

        &.completeAll {
          opacity: 0;
          //transform: rotateY(180deg);
          transform: rotateX(180deg);
          transition: all ease .3s;
        }

        .badge {
          position: absolute;
          top: @10px;
          right: @10px;
          width: @40px;
          height: @30px;
          background: #00C4FD;
          border-radius: @15px;
          font-size: @26px;
          font-family: ONEAN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: @30px;
          text-align: center;

          &.empty {
            background-color: #FDA100;
          }
        }

        .text {
          font-size: @80px;
          font-family: ONEAN;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }

    .complete {
      text-align: center;
      margin-top: @40px;

      button {
        width: @200px;
        height: @66px;
        //background: linear-gradient(90deg, #FF7F7D, #FF5251);
        box-shadow: 0px 4px 20px 0px rgba(255, 83, 82, 0.3);
        border-radius: @33px;
        font-size: @28px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .btn {
    padding: 0 @175px;

    .begin {
      width: @400px;
      height: @110px;
      background: url("../../assets/images/benefitServe/puzzle/按钮.png") no-repeat center center/cover;
      font-size: @40px;
      font-weight: 800;
      color: #FFFFFE;
      line-height: @110px;
      text-align: center;
      position: relative;

      //&.disable {
      //  opacity: 0.5;
      //}

      .tip {
        font-weight: 400;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        padding: 0 @10px;
        height: @42px;
        line-height: @42px;
        background: #FFAE00;
        border-radius: @21px @21px @21px 0px;
        font-size: @22px;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .waiting {
      margin-top: @20px;
      width: @400px;
      height: @110px;
      background: url("../../assets/images/benefitServe/puzzle/开奖按钮.png") no-repeat center center/cover;
      font-size: @40px;
      font-weight: 800;
      color: #FFFFFE;
      line-height: @110px;
      text-align: center;
    }
  }
}

.rule-dialog {
  width: 75%;
  height: @900px;
  background-color: #fff;
  padding:@100px @40px @40px;
  border-radius: @40px;
  position: relative;
overflow-y: scroll;
  .title-img {
    width: @454px;
    height: @116px;
    margin: 0 auto -@40px;
    transform: translateY(-@100px);
    background: url('../../assets/images/benefitServe/puzzle/活动规则.png') no-repeat center center/cover;
  }

  .inner-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @36px;
    font-weight: 800;
    color: #007BFF;

    span {
      margin: 0 @10px;
    }

    img {
      width: @44px;
      height: @14px;
    }
  }

  .rule-info {
    margin: @40px 0 @60px;

    p {
      padding: @12px 0;
      font-size: @30px;
      font-weight: 400;
      color: #111111;

      span {
        float: right;
        color: #345F8E;
      }
    }
  }

  .reward-info {
    margin-top: @30px;

    p {
      font-size: @30px;
      font-weight: 400;
      color: #111111;
      line-height: @48px;
    }
  }
}

.pay-dialog {
  width: @640px;
  height: @399px;
  background: linear-gradient(0deg, #E4F5FF, #F3FBFF);
  border-radius: @40px;
  text-align: center;
  padding-top: @50px;

  .inner-title {
    font-size: @40px;
    font-weight: 800;
    color: #333333;
  }

  p {
    font-size: @80px;
    font-family: ONEAN;
    font-weight: bold;
    color: #007BFF;
    margin: @40px;
    line-height: 1;
  }

  .inner-btn {
    button {
      width: @200px;
      height: @86px;
      background: ~"rgba(0, 150, 225)";
      border-radius: @43px;
      font-size: @36px;
      font-weight: 400;
      color: #555555;

      &:last-of-type {
        background: linear-gradient(90deg, #0075FF, #0099FF);
        color: #FFFFFF;
        margin-left: @30px;
      }
    }
  }
}

.result-swiper {
  margin: @30px @30px 0;
  padding-bottom: @40px;

  /deep/ .swiper-pagination {
    position: absolute;
    bottom: @10px;

    .swiper-pagination-bullet {
      background-color: #cccccc;
      width: @60px;
      height: @4px;
      transition: all ease 0.3s;
      border-radius: 1px;
      &.swiper-pagination-bullet-active {
        background-color: #999999;
      }
    }
  }
  .acpic{
    img{
      border-radius: @20px;
    }
  }
  .gotMoney {
    position: relative;
    .img{
      width: @600px;
      height: @600px;
    }
    h1 {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: @100px;
      font-family: ONEAN;
      font-weight: bold;
      color: #C32B2A;
      background: linear-gradient(0deg, #FF7239 0%, #FF2817 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      span {
        font-size: @26px;
        font-weight: 400;
        color: #C32B2A;
      }
    }

    p {
      font-size: @26px;
      font-weight: 400;
      color: #FFFEFE;
      opacity: 0.5;
      position: absolute;
      bottom: @50px;
      width: 100%;
      text-align: center;
    }
  }
}

.myCardInfo {
  padding-top: @10px;
  text-align: center;
  font-size: @26px;
  color: #ff6633;
}

.result-dialog {
  text-align: center;

  > h1 {
    font-size: @40px;
    font-weight: 800;
    color: #FFFFFF;
    margin-bottom: @20px;
  }

  > p {
    font-size: @24px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
  }

  .card {
    font-size: @30px;
    font-weight: 400;
    color: #FFFFFF;
    position: relative;

    .btline {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, @20px);
      width: @200px;
      height: @2px;
      background: linear-gradient(90deg, rgba(253, 223, 141, 0), rgba(253, 223, 141, 0.98), rgba(253, 223, 141, 0));
      border-radius: 1px;
    }

    span {
      font-size: @80px;
      font-family: ONEAN;
      font-weight: bold;
      color: #FFFFFF;
      background: linear-gradient(0deg, #FF6600 0%, #FFAE00 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

    }
  }

  .statistic {
    display: flex;
    justify-content: center;
    margin-top: @60px;

    div + div {
      margin-left: @100px;
    }

    div {
      font-size: @36px;
      font-weight: 800;
      color: #3CADFE;
    }

    p {
      margin-top: @20px;
      font-size: @26px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .card-box_1 {
    display: grid;
    width: @600px;
    height: @600px;
    border-radius: @16px;
    margin: @50px auto 0;
    grid-gap: 1px;
    background-color: #fff;

    li {
      background-position: top left;
      background-size: @600px @600px;
    }

    &.full_4 {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);

      li {
        &:nth-of-type(2) {
          background-position: top right;
        }

        &:nth-of-type(3) {
          background-position: bottom left;
        }

        &:nth-of-type(4) {
          background-position: bottom right;
        }
      }
    }

    &.full_6 {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(2, 1fr);

      li {
        &:nth-of-type(2) {
          background-position: top right;
        }

        &:nth-of-type(3) {
          background-position: center left;
        }

        &:nth-of-type(4) {
          background-position: center right;
        }

        &:nth-of-type(5) {
          background-position: bottom left;
        }

        &:nth-of-type(6) {
          background-position: bottom right;
        }
      }
    }

    &.full_8 {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(4, 1fr);

      li {
        &:nth-of-type(2) {
          background-position: -@150px 0;
        }

        &:nth-of-type(3) {
          background-position: -@300px 0;
        }

        &:nth-of-type(4) {
          background-position: top right;
        }

        &:nth-of-type(5) {
          background-position: bottom left;
        }

        &:nth-of-type(6) {
          background-position: -@150px 100%;
        }

        &:nth-of-type(7) {
          background-position: -@300px 100%;
        }

        &:nth-of-type(8) {
          background-position: bottom right;
        }
      }
    }

    &.full_9 {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 1fr);

      li {
        &:nth-of-type(2) {
          background-position: top center;
        }

        &:nth-of-type(3) {
          background-position: top right;
        }

        &:nth-of-type(4) {
          background-position: center left;
        }

        &:nth-of-type(5) {
          background-position: center center;
        }

        &:nth-of-type(6) {
          background-position: center right;
        }

        &:nth-of-type(7) {
          background-position: bottom left;
        }

        &:nth-of-type(8) {
          background-position: bottom center;
        }

        &:nth-of-type(9) {
          background-position: bottom right;
        }
      }

    }

    .son {
      background-size: 100% 100%;
    }
  }

  .btn {
    margin-top: @80px;
  }

  .inner-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    button {
      font-size: @36px;
      font-weight: 800;
      color: #FFFFFF;
      width: @360px;
      height: @86px;
      border: 1px solid #000000;
      background: linear-gradient(0deg, #0075FF, #00AEFF);
      border-radius: @43px;
    }
  }
}

</style>
