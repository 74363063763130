import request from '@/utils/request'

//获取微信授权地址
export  function getAuthUrlAPI(data){
    return request({
        url:'/wechat/getAuthUrl',
        method:'post',
        data:data
    })
}
// 微信登录
export  function wxLogin(code,serviceCode){
    return request({
        url:`/wxh5/user/login?code=${code}&serviceCode=${serviceCode}`,
        method:'get',
    })
}

// 用户 注册 编辑
export  function userregisterAPI(data){
    return request({
        url:'/wxh5/user/register',
        method:'post',
        data:data,
    })
}
// 验证码
export  function verifyCodeAPI(phone,serviceCode){
    return request({
        url:`/common/verifyCode/sms/${phone}/${serviceCode}`,
        method:'get',
    })
}
// 公司
export  function companylistAllAPI(){
    return request({
        url:'/wxh5/company/listAll',
        method:'post',
    })
}