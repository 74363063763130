<template>
  <div>
    <!-- 蒙版 -->
    <transition name="fade">
      <div :style="{'backgroundColor': 'rgba(0,0,0,0.9)'}" class="mask" v-if="maskShow"></div>
    </transition>
    <transition enter-active-class="animated bounceInDown" leave-active-class="animated bounceOutUp">
      <div class="content" v-if="value" >
        <slot></slot>
        <div v-if="showClose" class="close">
          <img o-fb @click="$emit('input',false)" src="../../../assets/images/homePage/close.png" alt="">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    showClose: Boolean
  },
  data () {
    return {
      timer: null,
      time: 10,
      maskShow: false
    }
  },
  watch: {
    value (value, oldValue) {
      console.log('显示')
      // 关闭弹窗
      if (!this.value) {
        this.time = 400
      }else{
        this.time = 10
      }
      this.timer = setTimeout(() => {
        this.maskShow = !this.maskShow
        clearTimeout(this.timer)
      }, this.time)
    }
  },
  destroyed () {
    clearTimeout(this.timer)
  }
}
</script>
<style lang="less" scoped>
@import "../../../assets/css/pixel/pixel";
@import "../../../assets/css/minix/minix";

.mask {
  .fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.content {
  .fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //margin-top: -@120px;

  /deep/ .prompt {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: @18px;
    font-size: @30px;
    color: #333;
    left: 50%;
    transform: translateX(-50%);
  }

  /deep/ .icon-guanbi {
    color: #fff;
    margin-top: @30px;
    font-size: @60px;
    opacity: 0.9;
  }
}

.close {
  margin-top: @60px;

  img {
    width: @72px;
    height: @72px;
  }
}
</style>
