<template>
  <div class="home_erlyNewsBus_bigBox">
    <div class="home_erlyNewsBus_box">
      <!-- 顶部 -->
      <div class="home_erlyNewsBus_titleBox">
        <SvgIcon class="home_erlyNewsBus_svg" iconClass="icon-zaobanche"></SvgIcon>
        <div class="home_erlyNewsBus_title">聚集生物城热点</div>
        <div @click="goEarlyNewsBusMore" class="home_erlyNewsBus_more">查看更多</div>
      </div>
      <!-- 内容 -->
      <div @click="golink(index)" v-for="(item,index) in erlyNewsBusList" :key="index"
        class="home_erlyNewsBus_contentBox">
        <div class="home_erlyNewsBus_dian"></div>
        <div class="home_erlyNewsBus_month">{{item.month}}月</div>
        <div class="home_erlyNewsBus_xian"></div>
        <div class="home_erlyNewsBus_text">{{item.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { postmorningNewsBusAPI,postCommonAddClick } from '../../api/home'
export default {
  name: 'earlynewsbus',
  // 新闻早班车
  data() {
    return {
      erlyNewsBusList: [
        // {
        //   month:8,
        //   title:"你错过了生物城哪些大事？啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊"
        // },
      ]
    }
  },
  created() {
    this.postmorningNewsBus()
  },
  methods: {
    goEarlyNewsBusMore() {
      this.$router.push("/earlynewsbusmore")
    },
    postmorningNewsBus() {
      // 新闻早班车 首页
      postmorningNewsBusAPI()
        .then(res => {
          let list = res.data.data || []
          list.map(v => {
            if (v.video) {
              let videourl = JSON.parse(v.video)
              v.videos = videourl.url
            }
          })
          this.erlyNewsBusList = list
        })
    },
    golink(index) {
      //计算浏览量
      postCommonAddClick('morningNewsBus',this.erlyNewsBusList[index].morningNewsBusID)
      .then(res =>{
        // 去外链
        if (this.erlyNewsBusList[index].source == 2) {
  
          this.$router.push({
            name: 'article',
            query: {
              serviceID: this.erlyNewsBusList[index].serviceID,
              from: "新闻早班车"
            }
          })
          return
        }
        if (this.erlyNewsBusList[index].link) window.location.href = this.erlyNewsBusList[index].link
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.home_erlyNewsBus_bigBox {
  width: 100%;
}

.home_erlyNewsBus_box {
  width: @700px;
  height: @268px;
  margin-left: @25px;
  margin-top: @30px;
  border-radius: @20px;
  background: -webkit-linear-gradient(left, rgba(40, 120, 255, 0.08), rgba(0, 178, 119, 0.05));
}

.home_erlyNewsBus_titleBox {
  width: 100%;
  height: @66px;
  position: relative;
  margin-bottom: @28px;
}

.home_erlyNewsBus_svg {
  width: @200px;
  height: @32px;
  position: absolute;
  left: @20px;
  bottom: 0;
}

.home_erlyNewsBus_title {
  font-size: @24px;
  position: absolute;
  bottom: 0;
  left: @220px;
  color: #777777;
}

.home_erlyNewsBus_more {
  width: @130px;
  height: @44px;
  border-radius: @22px;
  background: rgba(40, 120, 255, 0.08);
  position: absolute;
  top: @20px;
  right: @20px;
  font-size: @24px;
  color: #2878FF;
  text-align: center;
  line-height: @44px;
}

.home_erlyNewsBus_contentBox {
  margin-top: @14px;
  width: 95%;
  height: @40px;
  display: flex;
  align-items: center;
  font-size: @28px;
  color: #333333;
}

.home_erlyNewsBus_dian {
  width: @6px;
  height: @6px;
  background: #2878FF;
  border-radius: @2px;
  margin-left: @30px;
}

.home_erlyNewsBus_month {
  width: @100px;
  height: @40px;
  margin-left: @16px;
  font-size: @30px;
}

.home_erlyNewsBus_xian {
  width: @1px;
  height: @26px;
  background: #999999;
  margin-left: @7px;
}

.home_erlyNewsBus_text {
  width: 100%;
  height: 100%;
  margin-left: @20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: @30px;
}
</style>
