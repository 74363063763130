import request from '@/utils/request'
// get
// export function getAAA(){
//     return request({
//         url:"",
//         method:'get',
//     })
// }
// // post
// export function postAAA(){
//     return request({
//         url:"",
//         method:'post',
//         data:data
//     })
// }
// 首页 生物科普
export function postbiologicalScience(serviceID){
    return request({
        url:"wxh5/biologicalScience/details/" + serviceID,
        method:'post',
    })
}
// BIO 生活
export function postbioLife(serviceID){
    return request({
        url:"wxh5/bioLife/details/" + serviceID,
        method:'post',
    })
}
// 首页 BIO 要闻
export function postbioNews(serviceID){
    return request({
        url:"wxh5/bioNews/details/" + serviceID,
        method:'post',
    })
}
// 首页 资讯头条
export function postheadlines(serviceID){
    return request({
        url:"wxh5/headlines/details/" + serviceID,
        method:'post',
    })
}
// 首页 生物早班车
export function postmorningNewsBus(serviceID){
    return request({
        url:"wxh5/morningNewsBus/details/" + serviceID,
        method:'post',
    })
}
// 首页 轮播图
export function postindexCarousel(serviceID){
    return request({
        url:"wxh5/indexCarousel/details/" + serviceID,
        method:'post',
    })
}
// 人才公寓
export function posttalentsApartment(serviceID){
    return request({
        url:"wxh5/talentsApartment/details/" + serviceID,
        method:'get',
    })
}
// 防疫政策 首页
export function postepidemicPreventionPolicy(serviceID){
    return request({
        url:"wxh5/epidemicPreventionPolicy/details/" + serviceID,
        method:'get',
    })
}

// 园区通知 详情
export function noticeDetail(serviceID){
  return request({
    url: `/wxh5/notice/details/${serviceID}`,
    method:'get'
  })
}
