<template>
    <div class="biologymore_bigBox">
        <div class="biologymore_box">
            <div class="biologymore_titlebox">
                <div class="biologymore_input_bigBox">
                    <input @keyup.enter="clickSearch" @focus="focusinput" v-model="inputSearchValue"
                        class="biologymore_input_box" placeholder="输入标题搜索" type="search" />
                    <div>
                        <SvgIcon class="biologymore_input_svg" iconClass="icon-sousuo-10"></SvgIcon>
                    </div>
                    <div v-if="inputSearchValue != ''" @click="clearSearch" class="hoteventsmore_input_X">
                        <svg t="1665641559757" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="22455" width="16" height="16">
                            <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#AAAAAA"
                                p-id="22456"></path>
                            <path
                                d="M583.68 512l136.533333-136.533333c20.48-20.48 20.48-51.2 0-71.68s-51.2-20.48-71.68 0l-136.533333 136.533333-136.533333-136.533333c-20.48-20.48-51.2-20.48-71.68 0s-20.48 51.2 0 71.68l136.533333 136.533333-136.533333 136.533333c-20.48 20.48-20.48 51.2 0 71.68s51.2 20.48 71.68 0l136.533333-136.533333 136.533333 136.533333c20.48 20.48 51.2 20.48 71.68 0s20.48-51.2 0-71.68l-136.533333-136.533333z"
                                fill="#FFFFFF" p-id="22457"></path>
                        </svg>
                    </div>
                    <div v-if="searchtype" @click="clickSearch" class="biologymore_input_search">搜索
                    </div>
                </div>
            </div>
            <!-- tab -->
            <div>
              <van-tabs swipe-threshold="1" v-model="active" @click="changeTabs">
                  <van-tab :title="item.name" :name="item.biologicalScienceTypeID" v-for="(item,index) in switchList" :key="index"></van-tab>
              </van-tabs>
            </div>
            <div class="biologymore_content_bigboxs">
              <m-scroll ref="mscroll" v-model="pages" :getData="postbiologicalSciencelist" emptyText="暂无数据">
                <div @click="golink(index)" :class="index===0?'firstbox':'biologymore_content_bigbox'"
                    v-for="(item,index) in biologymoreList" :key="index">
                    <div class="biologymore_content_box">
                        <img class="biologymore_contentbox_img" :src="item.urls" alt="">
                        <div class="biologymore_contentbox_title">
                            {{item.title}}
                        </div>
                        <div class="biologymore_contentbox_time">
                            <span style="color:#00B277">{{item.biologicalScienceTypeName}}</span>
                            <span style="margin-left:0.2rem">{{item.time}}</span>
                            <span style="margin-left:0.2rem">{{item.Views}}</span>
                            <i class="iconfont icon-liulanliang1"/>
                        </div>
                    </div>
                    <div class="biologymore_contentbox_xian"></div>
                </div>
              </m-scroll>
            </div>
        </div>
    </div>
</template>

<script>
import { postbiologicalSciencelistAPI, postbiologicalScienceTypelistAPI, postCommonAddClick } from '../../api/home'
export default {
    name: 'biologyMore',
    //更多生物科普
    components: {

    },
    data() {
        return {
          switchList: [],//分类列表
          active: 0, //tab标签页 下标
          searchtype: false,
          inputSearchValue: "",//输入框
          biologymoreList: [],
          biologymoreLists: [],
          newswitchList: [{
            name: '全部',
            biologicalScienceTypeID: 0
          }],
          pages: {
            page: 1,
            pageSize: 10,
            kv: {}
          }
        }
    },
    watch: {
    },
    created() {
        this.postbiologicalScienceTypelist()
    },
    methods: {
      focusinput() {
        //获取焦点
        this.searchtype = true
      },
      clearSearch() {
        this.active = 0
        this.inputSearchValue = ''
        this.pages.page = 1
        this.searchtype = false
        this.postbiologicalSciencelist()
      },
      clickSearch() {
        this.active = 0
        this.pages.page = 1
        this.postbiologicalSciencelist()
      },
      // 切换tab
      changeTabs(name, title) {
        this.active = name
        this.pages.page = 1
        this.postbiologicalSciencelist()
      },
      // 生物更多 请求分类
      postbiologicalScienceTypelist() {
        postbiologicalScienceTypelistAPI().then(res => {
          let list = res.data.data || []
          this.switchList = [...this.newswitchList, ...list]
          this.active = this.switchList[0].biologicalScienceTypeID
          this.postbiologicalSciencelist()
        })
      },
      // 列表数据
      postbiologicalSciencelist() {
        if (this.pages.page === 1) this.biologymoreList = []
        if(this.active > 0) {
          this.pages.kv = {
            title: this.inputSearchValue,
            biologicalScienceTypeID: this.active,
          }
        } else {
          // 查全部
          this.pages.kv = {
            title: this.inputSearchValue,
          }
        }
        postbiologicalSciencelistAPI(this.pages).then(res => {
          let list = res.data.data.list || []
          list.map(v => {
              v.Views = v.browseTotal
              v.time = v.createTime.slice(0, 16)
              let newpicture = JSON.parse(v.picture)
              v.urls = newpicture[0].url
          })
          this.biologymoreList = [...this.biologymoreList, ...list]
          this.$nextTick(_ => {
            this.$refs.mscroll.endSuccess(res.data.data)
          })
        })
      },
      golink(index) {
        //计算浏览量
        postCommonAddClick('biologicalScience', this.biologymoreList[index].biologicalScienceID).then(res => {})
        // 去外链
        if (this.biologymoreList[index].source == 2) {
            this.$router.push({
                name: 'article',
                query: {
                    serviceID: this.biologymoreList[index].serviceID,
                    from: "生物科普"
                }
            })
            return
        }
        if (this.biologymoreList[index].link) window.location.href = this.biologymoreList[index].link
      }
    },

}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.biologymore_bigBox {
    width: 100%;

}

.biologymore_box {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
}

.biologymore_titlebox {
    width: @690px;
    margin-left: @30px;
    position: relative;
}


.biologymore_input_bigBox {
    margin-top: @30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: @20px;
}

.biologymore_input_box {
    width: 100%;
    height: @70px;
    background: #F6F6F6;
    border: 1px solid #FFF;
    border-radius: @40px;
    font-size: @30px;
    /* border: 0 none; */
    /* outline: none; */
    padding-left: @60px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

input::-webkit-input-placeholder {
    /* 输入框提示文字颜色 */
    font-size: @30px;
}

.biologymore_input_svg {
    width: @30px;
    height: @30px;
    position: absolute;
    left: @20px;
    top: @20px;
}

.hoteventsmore_input_X {
    width: @20px;
    height: @30px;
    position: absolute;
    right: @115px;
    top: @20px;

}

.biologymore_input_search {
    width: @70px;
    height: @70px;
    margin-left: @20px;
    /* display: flex;
    align-items: center; */
    text-align: center;
    line-height: @70px;
    font-size: @28px;
    color: #00B277;
}



.navber_bottom_box {
    width: 100%;
    height: 7vh;
    display: flex;
    position: fixed;
    bottom: 0;
}

.navber_bottom_smallBox {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: @20px;
}

.navber_bottom_svg {
    width: @40px;
    height: @40px;
    margin-left: 50%;
    transform: translate(-50%);
}

/deep/ .van-tabs__line {
    background: #00B277;

}

/deep/.van-tab {
    font-size: @30px;
}

/deep/.van-tab--active {
    font-weight: bold;
    font-size: @34px !important;
}

.biologymore_content_bigboxs {
    width: 100%;
    flex: 1;
    overflow-y: auto;
}

.biologymore_content_bigbox {

    width: 100%;
    background: #FFF;
    margin-top: @20px;
    border-radius: @20px;
}

.firstbox {
    width: 100%;
    background: -webkit-linear-gradient(top, rgba(0, 178, 119, 0.1), rgba(0, 178, 119, 0));
    border-radius: @20px;
    margin-top: @20px;
  padding-top: @20px;
}

.biologymore_content_box {
    width: @690px;
    margin-left: @30px;

}

.biologymore_contentbox_img {
    width: 100%;
    height: @290px;
    border-radius: @20px;
    object-fit: cover;
}

.biologymore_contentbox_title {
    font-size: @34px;
    margin-top: @20px;
}

.biologymore_contentbox_time {
    margin-top: @20px;
    font-size: @26px;
    color: #999999;
    height: @36px;
    line-height: @36px;
    display: flex;
    // align-items: center;

  .icon-liulanliang1 {
    color: #8D9794;
    font-size: @20px;
    margin-left: @6px;
  }

}

.biologymore_contentbox_svg {
    width: @26px;
    height: @22px;
    margin-left: @6px;
    margin-top:@5px;
}

.biologymore_contentbox_xian {
    margin-top: @30px;
    width: 100%;
    height: @10px;
    background: #F8F8F8;
}
</style>
