<template>
  <div class="bigbox">
    <div class="head_bigbox">
        <div @click="expecttype = true" class="head_leftbox">
            <img src="../../assets/home/人才认定.png" alt="">
        </div>
        <div @click="goServiceapartment" class="head_rightbox">
            <img src="../../assets/home/人才公寓.png" alt="">
        </div>
    </div>
    <div class="xian"></div>
    <div class="content_bigbox">
      <div class="content_title">人才招聘</div>
      <div @click="goRecruitmore(item, index)" class="content_box" v-for="(item,index) in contentList" :key="index">
        <div class="content_textbox">
          <div class="content_textleft">
            {{item.jobName}}
          </div>
          <div  class="content_textright">
            {{item.money ?item.money:"面议"}}
          </div>
        </div>
        <div class="content_textbottom">
          <SvgIcon class="content_svg" iconClass="icon-suoshugongsiquancheng"></SvgIcon>
          <span class="content_texttype">{{item.companyName}}</span>
        </div>
      </div>
    </div>
    <div @click="goServicemore" class="content_bottombox" v-if="contentList.length > 0">
      更多招聘
    </div>

    <!--  弹框  -->
    <van-popup v-model="expecttype" round closeable @click-close-icon="expecttype = false">
      <div class="wrapper">
        <div class="block" >
          <img src="../../assets/home/人才.jpg" alt="">
          <p class="tips">长按图片识别</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { postRecruitment } from '../../api/personnel'
import {postCommonAddClick} from "@/api/home";
export default {
    name: 'servicePage',
    // 人才招聘
    components: {

    },
    data() {
        return {
            contentList:[],//列表
            expecttype: false,//敬请期待 弹窗
        }
    },
    created(){
      console.log(this.$router)
        this.postRecruitmentAPI()
    },
    methods:{
        goServicemore(){
            this.$router.push('/servicemore')
        },
        goServiceapartment(){
            this.$router.push('/serviceapartment')
        },
        // 人才招聘详情
        goRecruitmore(item, index){
          this.addViews(this.contentList[index].recruitmentID, 'recruitment')
          this.$router.push(`/recruitmore?id=${item.serviceID}`)
        },
        //  增加阅读量
        addViews(id, key) {
          postCommonAddClick(key, id)
        },
        postRecruitmentAPI(){
            postRecruitment()
            .then(res =>{
                let list = res.data.data || []
                list.map(v =>{
                    if(v.negotiable == 0){
                        v.money = String(v.minSalary*0.001) + '-' + String(+ v.maxSalary*0.001) + "K"
                    }
                })
                this.contentList = list
            })
        },
        closeExpect() {
            this.expecttype = false
        },
    }

}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";
@import "../../assets/css/minix/minix";
.head_bigbox{
    width: 100%;
    padding: @30px;
    display: flex;
}
.head_leftbox{
    width: @335px;
    height: @120px;

}
.head_rightbox{
    width: @335px;
    height: @120px;
    margin-left: @20px;
}
.xian{
    width: 100%;
    height: @10px;
    background: #F8F8F8;

}
.content_bigbox{
    width: 100%;

}
.content_title{
    margin-top: @30px;
    margin-left: @30px;
    font-size: @34px;
    font-weight: bold;
}
.content_box{
  width: @690px;
  margin-top: @20px;
  margin-left: @30px;
  background: #FAFAFA;
  padding: @30px @30px @25px @30px;
  border-radius: @10px;
}
.content_textbox{
  width: 100%;
  font-size: @34px;
  line-height: @48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.content_textleft{
    max-width: @420px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}
.content_textright{
    color: #EF882C;
}
.content_textbottom{
  width: 100%;
  margin-top: @10px;
  font-size: @28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.content_svg{
    width: @30px;
    height: @30px;
}
.content_texttype{
  font-size: @28px;
  color: #666666;
  margin-left: @10px;
  line-height: @28px;
  word-break: break-word;
  .line1
}
.content_bottombox{
    color: #2878FF;
    font-size: @28px;
    text-align: center;
    margin-top: @30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my_expectbigbox {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.my_expectbox {
    width: @640px;
    // height: @430px;
    background: #FFFFFF;
    border-radius: @20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.my_svg {
    width: @30px;
    height: @30px;
    position: absolute;
    top: @30px;
    right: @30px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: @30px @40px;

  .block {
    width: @500px;
    height: auto;
    background-color: #fff;

    .tips {
      font-size: @26px;
      color: #666666;
      text-align: center;
      margin-top: @30px;
    }
  }
}


</style>
