<template>
    <div class="BIMmore_bigbox">
      <div class="BIMmore_topbox">
        <div class="BIMmore_topbox_blackbox"></div>
        <img class="BIMmore_topbox_img" :src="topbox.url" alt="">
        <div class="BIMmore_topbox_title">{{topbox.title}}</div>
        <div class="BIMmore_topbox_content">{{topbox.content}}</div>
      </div>
      <div class="BIOmore_input_bigBox">
        <input @keyup.enter="clickSearch" @focus="focusinput" v-model="inputSearchValue"
               class="BIOmore_input_box" placeholder="输入标题搜索" type="search">
        <div>
          <SvgIcon class="BIOmore_input_svg" iconClass="icon-sousuo-10"></SvgIcon>
        </div>
        <div v-if="inputSearchValue != ''" @click="clearSearch" class="hoteventsmore_input_X">
          <svg t="1665641559757" class="icon" viewBox="0 0 1024 1024" version="1.1"
               xmlns="http://www.w3.org/2000/svg" p-id="22455" width="16" height="16">
            <path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" fill="#AAAAAA"
                  p-id="22456"></path>
            <path
              d="M583.68 512l136.533333-136.533333c20.48-20.48 20.48-51.2 0-71.68s-51.2-20.48-71.68 0l-136.533333 136.533333-136.533333-136.533333c-20.48-20.48-51.2-20.48-71.68 0s-20.48 51.2 0 71.68l136.533333 136.533333-136.533333 136.533333c-20.48 20.48-20.48 51.2 0 71.68s51.2 20.48 71.68 0l136.533333-136.533333 136.533333 136.533333c20.48 20.48 51.2 20.48 71.68 0s20.48-51.2 0-71.68l-136.533333-136.533333z"
              fill="#FFFFFF" p-id="22457"></path>
          </svg>
        </div>
        <div v-if="searchtype" @click="clickSearch" class="BIOmore_input_search">搜索</div>
      </div>
      <m-scroll ref="mscroll" v-model="pages" :getData="postbioLifelist" emptyText="暂无数据">
        <div class="contentList" @click="golink(index)" v-for="(item,index) in BIOcontentBoxList" :key="index">
          <div class="BIO_contentbox">
            <div class="my_swipe_videoplayerboxs">
              <div v-if="item.type == 1" class="my_swipe_videoplayerbox"></div>
              <div v-if="item.type == 1" class="" >
                <SvgIcon class="my_swipe_videoSvg" iconClass="icon-play"></SvgIcon>
              </div>
              <img class="BIO_contentbox_img" :src="item.url" alt="">
            </div>
            <div class="BIO_contentbox_title">
              {{item.title}}
            </div>
            <div class="BIO_contentbox_time">
              <SvgIcon class="BIO_contentbox_svg" iconClass="icon-liulanliang"></SvgIcon>
              <span class="BIO_contentbox_svgs">{{item.Views}}</span>
              <span>{{item.time}}</span>
            </div>
          </div>
          <div class="biologymore_contentbox_xian"></div>
        </div>
      </m-scroll>
      <!-- 视频 -->
      <div v-if="videoplay_Box" class="videoplay_Box">
        <div @click="closeVideoplayBox">
          <SvgIcon class="videoplay_closeSvg" iconClass="icon-guanbi"></SvgIcon>
        </div>
        <video controls autoplay webkit-playsinlin="true" playsinline="true" x5-video-player-type="h5"
               x5-video-player-fullscreen="true" :src="videourl">
        </video>
      </div>
    </div>
</template>

<script>
import { postbioLifelistAPI, postbioNbioLifeTypeAPI,postCommonAddClick } from '../../api/home'
export default {
    name: 'BIOmore',
    // BIO详情
    data() {
        return {
          videourl: "",
          videoplay_Box: false,
          topbox: {
            title: '',
            content: '',
            url: '',
          },
          inputSearchValue: '',
          searchtype: false,
          BIOcontentBoxList: [],
          pages: {
            page: 1,
            pageSize: 10,
            kv: {}
          },
        }
    },
    created() {
      this.postbioLifelist()
      this.postbioNbioLifeType()
    },
    methods: {
      focusinput() {
        //获取焦点
        this.searchtype = true
      },
      clearSearch() {
        this.inputSearchValue = ''
        this.pages.page = 1
        this.searchtype = false
        this.postbioLifelist()
      },
      clickSearch() {
        this.pages.page = 1
        this.postbioLifelist()
      },
      // BIO 生活管理 横向轮播
      postbioNbioLifeType() {
        postbioNbioLifeTypeAPI().then(res => {
          //顶部盒子
          let list = res.data.data || []
          list.map(v => {
            if (v.bioLifeTypeID == Number(this.$route.query.typeID)) {
              this.topbox.title = v.name
              this.topbox.content = v.description
              let newurl = JSON.parse(v.picture)
              this.topbox.url = newurl[0].url
            }
          })
        })
      },
      // 列表数据
      postbioLifelist() {
        if (this.pages.page === 1) { this.BIOcontentBoxList = [] }
        this.pages.kv.title = this.inputSearchValue
        this.pages.kv.bioLifeTypeID = Number(this.$route.query.typeID)
        postbioLifelistAPI(this.pages).then(res => {
          let list = res.data.data.list
          list.map(v => {
              v.Views = v.browseTotal
              v.time = v.createTime.slice(0, 16)
              let newurl = JSON.parse(v.picture)
              v.url = newurl[0].url
              if (v.video) {
                  let videourl = JSON.parse(v.video)
                  v.videos = videourl.url
              }
          })
          this.BIOcontentBoxList = [...this.BIOcontentBoxList, ...list]
          this.$nextTick(_ => {
            this.$refs.mscroll.endSuccess(res.data.data)
          })

        })
      },
        closeVideoplayBox() {
            // 关闭播放器
            this.videoplay_Box = false
        },
        golink(index) {
            //计算浏览量
            postCommonAddClick('bioLife', this.BIOcontentBoxList[index].bioLifeID)
            .then(res => {
            })
            // 去外链
            if (this.BIOcontentBoxList[index].source == 2) {
                this.$router.push({
                    name: 'article',
                    query: {
                        serviceID: this.BIOcontentBoxList[index].serviceID,
                        from: "BIO生活"
                    }
                })
                return
            }

            if (this.BIOcontentBoxList[index].type == 1) {
                // 打开播放器
                this.videourl = this.BIOcontentBoxList[index].videos
                this.videoplay_Box = true
            } else {
                if (this.BIOcontentBoxList[index].link) window.location.href = this.BIOcontentBoxList[index].link
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/pixel/pixel";

.BIMmore_bigbox {
  width: 100%;
  height: 100%;
}

.BIMmore_box {
    width: 100%;
    height: 100vh;
}

.BIMmore_topbox {
    width: 100%;
    height: @360px;
    /* background: rgb(165, 165, 252); */
    position: relative;
}

.BIMmore_topbox_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BIMmore_topbox_blackbox {
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
}


.BIMmore_topbox_title {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: @48px;
    font-size: @54px;
    color: #FFF;
}

.BIMmore_topbox_content {
    width: @600px;
    height: @84px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: @132px;
    font-size: @30px;
    color: #FFF;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*只显示3行，如果不设置这个属性，会出现文本被拦腰截断的现象*/
    -webkit-box-orient: vertical;
}

.BIOmore_input_bigBoxs {
    // width: @690px;
    // margin-left: @30px;
}

.BIOmore_input_bigBox {
    margin-top: @30px;
    width: @690px;
    margin-left: @30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: @20px;
}


.BIOmore_input_box {
    width: 100%;
    height: @70px;
    background: #F6F6F6;
    border: 1px solid #FFF;
    border-radius: @40px;
    font-size: @30px;
    /* border: 0 none; */
    /* outline: none; */
    padding-left: @60px;
    overflow: hidden;
    /*溢出的部分隐藏*/
    white-space: nowrap;
    /*文本不换行*/
    text-overflow: ellipsis;
    /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

input::-webkit-input-placeholder {
    /* 输入框提示文字颜色 */
    font-size: @30px;
}

.BIOmore_input_svg {
    width: @30px;
    height: @30px;
    position: absolute;
    left: @20px;
    top: @20px;
}

.hoteventsmore_input_X {
    width: @20px;
    height: @30px;
    position: absolute;
    right: @115px;
    top: @20px;

}

.BIOmore_input_search {
    width: @70px;
    margin-left: @20px;
    /* display: flex;
    align-items: center; */
    text-align: center;
    line-height: @70px;
    font-size: @28px;
    color: #00B277;
}

.BIO_contentbox {
    width: @690px;
    margin-left: @30px;
    margin-top: @20px;
}



.BIO_contentbox_img {
    width: 100%;
    height: @290px;
    border-radius: @20px;

    object-fit: cover;
}

.BIO_contentbox_title {
    font-size: @34px;
    margin-top: @20px;
}

.BIO_contentbox_time {
    margin-top: @20px;
    font-size: @26px;
    color: #999999;
    height: @26px;
    display: flex;

}

.BIO_contentbox_svg {
    width: @26px;
    height: @22px;
    margin-left: @5px;
    margin-top: @2px;
}
.BIO_contentbox_svgs{
    margin-left: @5px;
    margin-right: @10px;
}

.biologymore_contentbox_xian {
    margin-top: @40px;
    width: 100%;
    height: @10px;
    background: #F8F8F8;
}

.videoplay_Box {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background: #000
}

.videoplay_closeSvg {
    width: @40px;
    height: @40px;
    position: fixed;
    top: @40px;
    right: @40px;
    z-index: 10;

}

.my_swipe_videoplayerboxs{
    width: 100%;
    margin-top: @20px;
    height: @290px;
    border-radius: @20px;
    position: relative;
}
.my_swipe_videoplayerbox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: @10px;
}
.my_swipe_videoSvg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: @60px;
    height: @60px;
}
</style>
