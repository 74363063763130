<template>
<div>
  <!-- 遮罩 -->
  <transition name="fade">
    <div class="mask" @click="$emit('input', false)" v-if="value"></div>
  </transition>
  <popup v-model="value" position="bottom" :hide-on-blur='false' :show-mask='false'>
    <div @click="$emit('input', false)" class="previewTitle">
      <p>{{title}}</p>
      <i o-fb class="iconfont icon-close"></i>
    </div>
    <div class="prizeDetail">
      <slot></slot>
    </div>
  </popup>
</div>
</template>

<script>
  import {Popup} from 'vux'

  export default {
    props: {
      value: Boolean,
      title: String
    },
    components: {
      Popup
    }
  }
</script>

<style lang="less" scoped>
  @import '../../assets/css/pixel/pixel.less';
  @import '../../assets/css/minix/minix.less';
  .mask{
    .fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  .vux-popup-dialog {
    border-top-left-radius: @24px;
    border-top-right-radius: @24px;
  }
  .previewTitle {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: @24px @26px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    p {
      color: #2a2a2a;
      font-size: @30px;
      margin-left: 50%;
      transform: translate(-50%);
    }

    .icon-close{
      font-size: @42px;
      line-height: @42px;
      position: relative;
      top: @4px;
      color: #888;
    }
  }
  /deep/.prizeDetail {
    background: #fff;
    margin-top: @88px;
    padding: @30px;
    max-height: @800px;
    overflow-y: auto;

    .prizeImg {
      text-align: center;
      img{
        width: @210px;
        height: @210px;
        border-radius: @8px;
        margin: auto;
        margin-bottom: @24px;
      }
    }

    .prizeImg+p {
      text-align: center;
      font-size: @28px;
      color: #929292;
      line-height: @42px;
      padding-bottom: @22px;
      margin-bottom: @30px;
    }

    .info {
      li {
        display: flex;

        &:not(:first-child) {
          margin-top: @12px;
        }

        a {
          text-decoration: underline;
          color: rgb(38, 162, 255);
        }

        >span:first-child {
          color: #ff6633;
          min-width: @140px;
        }

        >span:last-child {
          text-align: left;
          word-break: break-word;
          .one-line;
          width: @375px;
        }

        font-size:@28px;
        line-height:@42px;
        color:#222222;
        &.prizeLink{
          display: inline-block!important;
          span:last-child{
            width: auto!important;
            white-space: inherit!important;
            a{
              word-break: break-all;
            }
          }
        }
        &.prizeInfo {
          display: inline-block;
          text-align: left;
          width: 100%;
          margin-top: @6px;
          article {
            display: inline;
            text-align: left;
          }

          /deep/ p {
            display: inline;
            font-size: @28px !important;
            line-height: @54px !important;
            color: #222222 !important;
          }
        }
      }
    }
  }
</style>
